import * as React from 'react';

export default function Terms() {

    React.useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant'
        })
      }, [])

    return(
        <div className='defPageMarg' style={{margin:40}}>
            <div className='defaultTitle'>
            TERMS & CONDITIONS
            </div>
<p>
  <span className="underlineTitle">
    <strong>Terms and Conditions</strong>
  </span>
</p>
<p>We are interested in selling precious metal bullion to you. When you are ready to purchase precious metals to add to your portfolio, buy from us. Learn more here.&nbsp;</p>
<p>PLEASE READ CAREFULLY: These Terms and Conditions govern the the sale of bullion by Gold Guys Bullion LLC to the Purchaser (“Purchaser”, “You”, or “Your”, as the contextrequires). By offering to purchase or purchasing bullion from Gold Guys Bullion LLC, including via our website or telephone number noted on our website, you represent and warrant that you have the right and legal capacity to enter into these Terms and Conditions, have read, understood and agree to these Terms and Conditions and that these Terms and Conditions constitute an agreement made by and between Gold Guys Bullion LLC and you, personally, and/or if applicable, on behalf of the entity for whom you are purchasing bullion, as the case may be. If you do not agree with these Terms and Conditions, you are expressly prohibited from purchasing bullion from Gold Guys Bullion LLC, and must discontinue your use of our website immediately.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Bullion Market Price and Availability Change Constantly / Gold Guys Bullion LLC Set</strong>
  </span>
  <span className="underlineTitle">
    <strong>Bullion Prices</strong>
  </span>&nbsp;&nbsp;</p>
<p>The bullion products we offer for sale, are commodities traded on various markets, and the market price of such types of bullion, therefore, changes constantly in accordance with market&nbsp; forces and supply and demand. Although we indicate sales prices on our website, market movements may occur more rapidly than updates allow. You acknowledge and agree that Gold Guys Bullion LLC&nbsp; alone shall set the bullion purchase price for all bullion that we offer for&nbsp; sale and buyback prices for all bullion offered for sale by us.</p>
<p>
  <span className="underlineTitle">
    <strong>Bullion Trading Activity</strong>
  </span>
</p>
<p>From time to time, trading activity in the bullion market may be very high, which may interfere with your ability to contact us by telephone to conduct bullion trading. Regardless of trading activity volume, we shall continue to conduct bullion trading only by telephone, or through our website. Gold Guys Bullion LLC does not accept or conduct any bullion transactions through any other means other than by telephone or through our website.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Placement of Transaction Creates a Binding Agreement</strong>
  </span>
</p>
<p>Gold Guys Bullion LLC&nbsp; are a leading bullion dealer in the bullion market, with the critical needto maintain our reputation and competitive position to ensure that we maintain a high volume of trading opportunities. For this reason, you acknowledge and agree that we are entitled to rely on the verbal promise from you, or your offer,&nbsp; to purchase bullion from us and pay for your order, as the case may be. In furtherance of this, you agree that your&nbsp; purchase bullion from us, and/or placement of a bullion sale or purchase transaction with us, create a binding, legally enforceable agreement in which you agree to purchase bullion from us. You shall not thereafter cancel any bullion transaction before, in the case of the purchase of bullion from us, making payment, in full. In the case that you have purchased bullion from us, once you have paid for the purchased bullion in full, we may buy back the bullion product(s) upon request, in accordance with the&nbsp;</p>
<p>“Buyback Terms” contained herein.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Email Confirmation and Purchase Order / Seller to Allow Emails from</strong>
  </span>
</p>
<p>
  <span className="underlineTitle">
    <strong>Gold Guys Bullion LLC</strong>
  </span>
</p>
<p>After we have confirmed your transaction, we will email you a copy of your purchase order specifying the details of your sale and our purchase, or your purchase and our sale, as may be applicable. Failure by you to receive this email does NOT invalidate or otherwise cancel your transaction with us; you are still obligated to ship your precious metals to us or pay for precious metals purchased from us, in full and on time. You shall take all steps necessary, including updating address books and adjusting spam or junk-mail filters, to ensure that you can timely receive all emails from us, complete with any attachments, in furtherance of your transaction(s) with us and these Terms and Conditions.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Shipment of Bullion</strong>
  </span>
</p>
<p>In the event that you sell bullion to us in a buyback case: You shall promptly ship all bullion you have agreed to sell to us within two business days of entry into the relevant transaction. Packages must be received by Gold Guys Bullion LLC no later than ten business days following the relevant transaction date. You are responsible for all costs associated with shipping your metals to us, which costs may be deducted from the proceeds of your sale if you use our shipping label. We are not responsible for any lost, misrouted, or damaged packages.&nbsp;</p>
<p>If you purchase bullion from us: Within five business days from when we receive payment in full for your bullion order (unless the clearing time applicable to your method of payment exceeds five business days, or your order is subject to exceptions), we will pack your bullion, subject to bullion product availability. It is critical that the address provided for your shipment is accurate and that you are available to receive the shipment when it is en route to you. From time to time, the shipment time of your order may be delayed for reason(s) including but not limited to reductions in our inventory as a result of increased demand for certain bullion products and/or the reduced supply available to us. We will endeavor to update our website with any delays&nbsp;</p>
<p>applicable to particular bullion products as may arise from time to time, and include this information in your order confirmation email/purchase order.&nbsp;</p>
<p>If we are unable to comply with the shipment time noted above, we will endeavor to notify you as soon as reasonably possible. Upon receipt of such notice, you may elect to (a) accept the new shipment time provided by us, which new time shall not exceed an additional thirty days; or (b) request that we buyback your order pursuant to the ‘Buyback Terms’. If we are unable to&nbsp;</p>
<p>ship your order within the new shipment time estimated, then after this new shipment time has passed, we will buyback your bullion order pursuant to the ‘Buyback Terms’, and remit payment to you for the value of (a) the voluntary liquidation; or (b) your payment for your order, including any shipping fees paid by you, whichever is greater.&nbsp;</p>
<p>If your order is shipped or arrives late, at our sole discretion, we may issue you a coupon for the cost of shipping. Any coupon so issued shall be in a form and subject to such terms and conditions as determined by us in our sole Discretion.&nbsp;</p>
<p>We reserve the right to substitute a different shipping method other than that which you selected on your order. Where the actual shipping method costs less than the method of shipping you had selected on your order, we will issue you a coupon for use with us, subject to such terms and conditions as determined by us in our sole discretion. We will insure the full value of your&nbsp;</p>
<p>shipment to the address provided when your order was placed, or to an address accepted by PayPal/Zoho Payments, as may be applicable.&nbsp;</p>
<p>Should you choose to redirect your order to be shipped to any other location or be accepted by any other person (drop at the neighbor, front desk, mailroom, building manager, etc.), and/or waive the requirement for a signature, you agree to incur all risk and liability associated with such order. Additionally, should your shipment be disrupted or delayed in any way for more than 48 hours from the anticipated delivery date, notify us immediately. We assume the responsibility to pursue any claim with the insurance company provided; you agree to take all reasonable actions to assist in the shipment’s recovery through the internal, insurance company, police, and courier investigations. Assistance could include but is not limited to filling out reports (including potential police reports) and providing all required information that could aid in the recovery of your shipment. Failure to cooperate in recovery efforts will void any insurance coverage.&nbsp;</p>
<p>Shipments deemed lost or damaged will have an insurance claim filed for them. Upon filing a claim, we reserve the right to re-ship your items purchased or refund your money at our discretion.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Purchase Order Cancellation Policy</strong>
  </span>
</p>
<p>When you confirm the sale of bullion to us or the purchase of bullion from us, you enter into a binding contractual agreement with us to ship all of the agreed precious metals to us or purchase all of the agreed precious metals from us, as may be applicable, and CAN NOT CANCEL THE TRANSACTION. Should you wish to liquidate your trade, or in the event that you sell bullion, if you do not ship the metals within two business days (as noted in ‘Shipment of&nbsp;</p>
<p>Bullion’, above), we will cancel and liquidate your transaction based on the prevailing market purchase price at the time of liquidation. You will be liable for, and charged for, any difference in the event of a market price increase. (See ‘Involuntary Liquidation of Your Order’ for more details.)&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Return Policy</strong>
  </span>
</p>
<p>We strive for 100% customer satisfaction, which is why we provide you the opportunity to return any product purchased from us for store credit, refund, or exchange, less any market loss that may have occurred due to price changes.&nbsp;</p>
<p>&nbsp;&nbsp;</p>
<p>If you are not happy with your order, contact our support team at sales@goldguys.com or support@goldguys.com within two (2) business days of delivery. We will provide you with a return shipping quote and advise you of any applicable market loss due to price changes that will be subtracted from your proceeds. After your authorization, we will issue your return label, and once the product is returned to us, we will proceed with issuing store credit, refund, or exchange as agreed.&nbsp;</p>
<p>All items must be in original packaging and original condition. We reserve the right to refuse any returned product that does not reasonably meet these conditions. Any payment charges on the original order are not refundable. You are responsible for payment of return shipping &amp; insurance costs, as well as any market loss. You accept liability for loss or damages on your returned items if you choose to ship your package through an alternate method and/or&nbsp;</p>
<p>uninsured. Unapproved returns may be refused and returned to the sender. Refunds to a credit card or through PayPal will be charged a 4% restocking fee. We reserve the right to issue a refund if a suitable exchange is not available.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Privacy</strong>
  </span>
</p>
<p>In order to process your transaction with Gold Guys Bullion LLC, we may request that you provide certain personal information, including but not limited to your first and last name, company, email address, telephone number, mailing address, billing address, shipping address, country of residence, credit card type, credit card number, credit card expiration date, and credit card security code. In addition, we may keep a recording of your purchase or sale transaction with us to verify the terms of your purchase or sale; aid in training, auditing, or quality control; comply with any laws as now exist or may hereafter exist, or for any other reason reasonably related to the business of dealing in bullion. Your personal information, including any personal information that forms part of any bullion transaction that you may enter into with us, is handled,&nbsp;</p>
<p>processed, and stored by us in accordance with our Privacy Policy, which is hereby incorporated by reference into these Terms and Conditions by Reference.&nbsp;</p>
<p>You hereby acknowledge and agree as follows: (a) that your entry into a transaction with Gold Guys Bullion LLC indicates your consent to the collection, use, and disclose of your personal information as outlined in these Terms and Conditions and our Privacy Policy; (b) that you have a duty to ensure that the information that you provide to us is truthful, current, complete, and accurate; and (c) that Gold Guys Bullion LLC, and its designees and agents, may contact you by any available means, including, but not limited to, by phone, by text message, and by email in order to effect or facilitate transactions with you.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Payment</strong>
  </span>
</p>
<p>In the event that you sell bullion to us: Payment shall be in U.S. dollars, as may be applicable and specified in the relevant purchase order. Payment will be issued to you by mail within two business days of our receipt of the bullion you have agreed to sell to us, received through the mail or a third-party carrier. Standard payment is made by check, mailed to the address you</p>
<p>provided to us at the time of entry into a transaction with us. For an additional $35 fee, you can request our check be sent to you via overnight courier. A physical address is required for delivery.</p>
<p>In the event that you purchase bullion from us: You shall submit payment in full for the bullion at the time of purchase. Direct Bank or Wire transfer payments within two business days, and we shall receive full payment within three business days, of placing the relevant order by using one or more of our accepted methods of payment. Such payment must include the full amount owing to us, including but not limited to, payment for the bullion product(s), shipping and handling charges, and any transaction and/or other fees, as may be applicable. Should any delays or payment issues be encountered, you are required to contact Gold Guys Bullion LLC customer support immediately to inform them of said issues in order to guarantee pricing. Failure to notify us of payment delays could result in the liquidation of your order as contemplated in ‘Involuntary Liquidation of Your Order. All payments must be made in US Dollars, as indicated on the relevant purchase order. Upon our receipt of your payment, in full, we will hold your payment and your bullion product(s) ordered for the clearing time applicable to the method of payment(s) used by you. Once your payment has been verified and upon expiration of the relevant clearing time, the bullion product(s) will be released for shipment to you. Should any payment be found to be NSF, or should we learn of a returned payment, initiated by you, we will automatically add a $25 charge to your account/amount due and payable as an administration and processing fee&nbsp;</p>
<p>and you will be liable for payment.&nbsp;</p>
<p>We reserve the right, but undertake no obligation, to actively report and prosecute actual and suspected fraud. To that end, we may, at our discretion, require further authorization from you such as a telephone confirmation of transactions, and other information. We reserve the right to cancel, delay,&nbsp; refuse to ship, or recall from the shipper any transaction or shipment if fraud is&nbsp;</p>
<p>suspected. We capture certain information during the transaction process, including, without limitation, information that will be used to locate and identify individuals committing fraud. If any transaction is suspected to be fraudulent, we reserve the right, but undertake no obligation, to (a) submit all records, with or without a subpoena, to all law enforcement agencies and to any&nbsp;</p>
<p>relevant credit card company for fraud investigation, and/or (b) cooperate with relevant authorities to prosecute offenders to the fullest extent of the law.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Chargeback Policy</strong>
  </span>
</p>
<p>You agree not to file a credit card or debit card chargeback regarding any purchase and abide by the dispute resolution procedures listed. In the event that you breach this agreement and file a chargeback, upon a resolution in our favor of the chargeback by either the credit card issuing bank, the credit card processor or by VISA, MASTERCARD, or DISCOVER, you agree to&nbsp;</p>
<p>reimburse us for any costs incurred in researching and responding to such chargeback, including without limitation, our actual costs paid to the credit card processor or our banks, other third parties, and the reasonable value of the time of our employees and owners spent on the matter, as determined in our discretion in good faith. You further agree that all dispute resolution&nbsp;</p>
<p>procedures below will be deemed waived by you, that these amounts will be added to the original amount of the order, and that this total amount will then be immediately due and payable. If your chargeback is upheld, you agree to pay all of the same costs, in addition to the original purchase price.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Right to Inspect and Reject</strong>
  </span>
</p>
<p>In the event that you sell bullion to us, we inspect each package shipped by you as it arrives and reserves the right to reject, modify or liquidate the relevant transaction with respect to, any late or defective package, any package that you may send that contains items, not as represented you or that are damaged or worn in excess of industry standards, or any package&nbsp;</p>
<p>that does not otherwise comply with these Terms and Conditions. Packages shipped by you must be postmarked within two business days, and arrive to us no later than ten business days, following the date of the transaction you may enter with us. All damaged packages shipped by you will be refused for delivery and returned to you. If your package is returned to you, you will need to immediately contact us to arrange a reshipment of the bullion or liquidation of the transaction. Failure to contact us to make arrangements will result in your&nbsp; transaction being involuntarily liquidated on the eleventh business day following the date of the relevant transaction. We will contact you immediately if any discrepancy is discovered upon inspection of any package shipped by you, and every effort will be made to resolve the matter in a timely fashion. We reserve the right to have any non- standard precious metals products shipped by you assayed for pureness. You are responsible for the associated fees for such testing. Fees associated with any transaction may be deducted from the total agreed-upon payment due to you under the applicable purchase order.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Packaging of Bullion for Shipment by You</strong>
  </span>
</p>
<p>In the event that you sell bullion to us, you shall package all metal being shipped to us consistent with our Packaging Guidelines (which are a component of the shipping instructions and shipping label information provided to you to aid in the shipment of bullion to us).</p>
<p>Additionally, you shall fully insure your package for delivery, and provide us a detailed packaging slip which shall include, at a minimum, an itemized description of the contents of the package, your name, full mailing address, and your telephone number.&nbsp;</p>
<p>&nbsp;&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Buyback Terms</strong>
  </span>
</p>
<p>In the event that you purchase bullion from us, at any time after you have fully paid for your bullion product order, or even before we have shipped it to you, you can choose to sell your bullion order back to us at the market rate for such buybacks applicable at that time. Please contact us to discuss the buyback of your paid bullion order. The Terms and Conditions are applicable to our purchase of your bullion products, and these may be amended from time to time at our discretion. Find more information here.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Involuntary Liquidation of Your Order</strong>
  </span>
</p>
<p>In the event that you sell bullion to us: As contemplated earlier in these Terms and Conditions, if you fail to deliver the bullion you have agreed to sell to us in full and on time, we shall involuntarily cancel and liquidate your bullion sale as of the 11th business day after your transaction date. A completed purchase has occurred upon receipt of bullion by us (with respect to bullion purchase by us), or upon receipt of funds from you in our bank account (with respect to bullion sale by us).&nbsp;</p>
<p>In such event, we will charge your credit card for the value of the sale, such amount to depend on any market losses assessed between the transaction date and the time of non-payment or liquidation. Market loss or gain is calculated as the difference between the original purchase price, and the bullion London Fix closing price on the 11th business day after your transaction date.&nbsp;</p>
<p>If the bullion market has gone up since you placed your order, you will be charged the following:&nbsp;</p>
<p>A 10% cancellation fee; plus the market difference on your order computed at the closing price for your bullion on the London Metals Market Fixing for the 11th business day after you placed your order.&nbsp;</p>
<p>If the bullion market has gone down since you placed your order, you will be charged the following: A 10% cancellation fee; and you will not benefit from any difference in the&nbsp;</p>
<p>market price.&nbsp;</p>
<p>In the event that you purchase bullion from us: Should you fail to pay for your bullion order as required pursuant to these Terms and Conditions, we reserve&nbsp; the right to liquidate your order for market value at the time of such liquidation, in our sole discretion. We are under no obligation to take any steps or to refrain from taking any steps, in order to or in an attempt to maximize market value for your order and we take no responsibility. You remain responsible for&nbsp;</p>
<p>the full value of your order as agreed to at the time such order was placed by you and confirmed via purchase order. This responsibility includes, but is not limited to, your responsibility to pay us the difference in value in the event that the market value of your unpaid order is less than what you agreed to pay at the time of placing your order. The credit card information provided by you will be used by us to charge you for any amounts owing to us pursuant to the terms applicable to ‘Involuntary Liquidation of Your Order. If the market value of your unpaid order is greater than you had promised to pay for such order, any such gains remain the property of Gold Guys Bullion LLC.&nbsp;</p>
<p>Where you have placed more than one order with us, and have paid for one or more of the order(s) but have failed to pay for one or more of such other order(s) in accordance with these Terms and Conditions, any shipment of any paid order(s) may be held at our discretion until payment in full for all orders placed by you is received in accordance with these Terms and Conditions. At our sole discretion, we may elect to apply the money received by us for your&nbsp;</p>
<p>paid order(s) and/or the bullion product(s) comprising your paid order(s) against any payment(s) due and owing to us on account of your unpaid order(s). If, after exercise of any such election(s) by us, you still owe us any amount, we may, under our sole discretion, involuntarily liquidate any&nbsp;</p>
<p>remaining bullion product(s) in the quantity required by us, acting reasonably, in order to ensure that we are paid in full for all your orders and the applicable market loss.&nbsp;</p>
<p>The determination of any loss or gain of value on all or part of your order for the purposes of this section is calculated as the difference between the price you agreed to pay for the such bullion product(s) at the time your order was agreed to by us, and the market value of same obtained by us as at the time of liquidation.&nbsp;</p>
<p>Should you wish to reinstate your order(s) once involuntary liquidation has occurred, contact Gold Guys Bullion LLC customer support. Your order(s) will be reinstated at original pricing, or current pricing, whichever is higher at the time of reinstatement. Reinstatement of your order(s) will waive applicable outstanding market loss. However, you will be required to provide valid credit card details for this service if not already provided.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Reservations of Right</strong>
  </span>
</p>
<p>In addition to any other rights Gold Guys Bullion LLC may have pursuant to these Terms&nbsp;</p>
<p>of Purchase, we expressly reserve the following rights:&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Right to Refuse Service to Anyone</strong>
  </span>
</p>
<p>We reserve the right to refuse service to anyone, for any reason, at our sole Discretion.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Right to Correct Erroneous Orders</strong>
  </span>
</p>
<p>We reserve the right to correct any sale or purchase for obvious errors or computer-related problems, whether due to our fault or otherwise. We will make a good faith effort to notify you of any correction of your order without Delay.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Right to Cancel Any Pending Transaction</strong>
  </span>
</p>
<p>We reserve the right to cancel any pending transaction at any time for any reason. If we exercise this right, we shall cancel our purchase of your bullion, or your purchase of bullion from us, notify you of the cancellation, and (a) in the event that you have sold bullion to us, return any bullion or the current cash equivalent you may have delivered, less any costs and fees associated with canceling your order. In furtherance of this, you agree to immediately</p>
<p>return and refund any monies we may have paid; or (b) in the event that you have purchased bullion from us, refund to you all monies you may have paid us for such order, less any costs or fees associated with canceling your order, as may be applicable.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Right to Require Payment by Wire Transfer</strong>
  </span>
</p>
<p>In the event that you purchase bullion from us, we reserve the right to require payment via bank wire transfer.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Right to Withhold Shipment for Unpaid Multiple Orders / Right of Offset</strong>
  </span>
</p>
<p>In the event you place multiple purchases or sell orders with us and do not fulfill said orders according to these Terms and Conditions, we reserve the right to withhold shipment or payment of any orders that have been paid or delivered by you, until the unfulfilled orders have been resolved to our satisfaction, including the Right of Offset.&nbsp;</p>
<p>&nbsp;&nbsp;</p>
<p>The ‘Right of Offset’, as used in these Terms and Conditions, shall mean our right to apply any proceeds of any paid order and/or product against any of your other unfulfilled order’s proceeds and product. If you shall thereafter have a net balance owed to us, we may thereafter involuntarily liquidate the remaining net balance owed as provided herein. If you shall have a net&nbsp;</p>
<p>balance remaining for shipment or payment, then we shall ship the remaining portion of the order or send payment as provided herein.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Right to Withhold Payment for Outstanding Documents and</strong>
  </span>
  <span className="underlineTitle">
    <strong>Identification</strong>
  </span>
</p>
<p>We reserve the right to withhold payment of funds due to you, or to involuntarily liquidate, cancel or rescind any transaction, should any documents requested, including without limitation identification, be outstanding at the time of the relevant transaction or when requested by us.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Right to Accept or Reject Any Late or Defective Payment</strong>
  </span>
</p>
<p>In the event that you purchase bullion from us, we reserve the right to (a) accept any late or otherwise deficient payment(s), including but not limited to liquidation payment(s); and/or (b) reject any late or otherwise deficient payment(s), including but not limited to liquidation payment(s), at any time in our sole and absolute discretion.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Right to Hold Payment</strong>
  </span>
</p>
<p>In the event that you purchase bullion from us, we reserve the right to hold any payment received by us from you until it is verified and cleared and/or you have communicated with us and submitted valid credit card information. We note that this is done solely in the interest of fraud prevention, and all holds are removed promptly upon the completion of the above conditions.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Computation of Time</strong>
  </span>
</p>
<p>Unless otherwise expressly stated in these Terms and Conditions, the first day for computing a time period shall be the first business day following the placement of the respective transaction. If the last day of the time period shall fall on a weekend or federal holiday, or on any day the London Metals Market Fixing is closed, then the last day shall be moved forward in time to the first day that is not a weekend, federal holiday or any day the London Metals Market Fixing is closed.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Spot Alert Pricing Service</strong>
  </span>
</p>
<p>Our spot prices are updated every 60 seconds, throughout the day, to reflect live global markets. As such, if you have opted to receive pricing alerts, your alert could trigger at any time day or night. This alert does not constitute a locked spot price for buying or selling, nor any type of recommendation for action of any kind. This service is provided for purely informational purposes. We do not warrant the accuracy or completeness of information through this&nbsp;</p>
<p>service and it is not a substitute for your own monitoring of the market. You acknowledge that you are solely responsible for all monitoring and for all investment and other decisions based thereon, and that we have no liability for any market gain or loss, or any other liability, that you may incur as a result of or in connection with the pricing alert service.&nbsp;</p>
<p>It is expressly understood that, like all services, the pricing alert service is subject to delays and to failures that are beyond our control. We make no assurance that this service will be in continuous operation, nor suffer some malfunction. Your carrier plan’s SMS or phone message rates may apply in the event that you receive such alerts.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Best Price Guarantee</strong>
  </span>
</p>
<p>We will match any reputable competitor’s advertised all-in price on any product we sell. All-in pricing means the price after the respective competitor’s shipping and insurance charges have been added to their listed price. Should the competitor’s price still be lower, we will match it to the penny. This guarantee is only available only on orders to purchase bullion from us via&nbsp;</p>
<p>Telephone.&nbsp;</p>
<p>We reserve the right to suspend this guarantee during times of extreme market volatility and/or supply disruption, in order to ensure sustainability during times of otherwise high market risk. A resumption of this guarantee will follow at such time as markets have resumed normal trading activity.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Coupons and Discounts</strong>
  </span>
</p>
<p>We will, for promotional and customer reward purposes, periodically have coupon codes available for your use on orders that you may place with us. Unless otherwise stated, any such coupon will be valid until the stated expiration date or quantity threshold has been met. We reserve the right to activate and deactivate new and existing coupon codes, from time to time, as we see fit in our sole discretion. Should you have any concerns regarding a&nbsp; specific coupon, you are encouraged to call our toll-free number and speak to a member of our customer support team. All coupons and discounts are priced in U.S. dollars and no conversion is available.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Governing Law and Resolution of Dispu</strong>
  </span>tes&nbsp;</p>
<p>These Terms and Conditions, and any dispute arising hereunder, shall be governed by and construed pursuant to the laws of the State of Minnesota and the federal laws of the United States of America applicable therein. To the extent permitted by applicable law, you hereby expressly waive your right to a trial by jury in any proceeding or litigation brought against Gold Guys Bullion LLC with respect to these Terms and Conditions. Notwithstanding the foregoing,&nbsp;</p>
<p>Gold Guys Bullion LLC will have the right to seek injunctive or other equitable relief in any&nbsp;</p>
<p>provincial, state, or federal court as may be necessary in order to enforce these Terms and Conditions or prevent an infringement of a third party’s rights. In the event equitable relief is sought, you hereby irrevocably submit to the personal jurisdiction of such court and waive all objections to the Jurisdiction.&nbsp;</p>
<p>BY ENTERING INTO THESE TERMS AND CONDITIONS, YOU HEREBY IRREVOCABLY WAIVE ANY RIGHT YOU MAY HAVE TO JOIN CLAIMS WITH THOSE OF OTHERS IN THE FORM OF A CLASS ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS ARISING OUT OF, RELATING TO, OR CONNECTED WITH THESE TERMS AND CONDITIONS&nbsp;</p>
<p>MUST BE ASSERTED INDIVIDUALLY.&nbsp;</p>
<p>You further acknowledge and agree that, regardless of any statute or law to the contrary, any claim or cause of action you may have arising out of, relating to, or connected with any transaction contemplated hereunder must be filed within one calendar year after such claim or cause of action arises, or forever be barred.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Limitation of Liability</strong>
  </span>
</p>
<p>Our liability for any bullion purchase or sale shall not exceed the purchase price we agreed to pay for the purchase of your bullion or the sales price at which we agreed to sell bullion to you, as may be applicable.&nbsp;</p>
<p>WE SHALL IN NO EVENT BE LIABLE FOR INDIRECT, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND SHALL NOT BE LIABLE FOR ANY DELAYS OR FAILURES IN THE TRANSMISSION, RECEIPT, OR EXECUTION OF ORDERS, PAYMENTS, DELIVERIES, OR INFORMATION DUE TO THE INCAPACITY OR FAILURE OF COMPUTER OR COMMUNICATIONS EQUIPMENT OR FACILITIES, OR FOR ANY OTHER REASON WHICH IS OUTSIDE OUR CONTROL.&nbsp;</p>
<p>Some jurisdictions do not allow the exclusion or limitation of damages. If your jurisdiction does not allow the exclusion or limitation of damages, you should seek legal counsel to understand your legal rights under applicable law.&nbsp;</p>
<p>&nbsp;&nbsp;</p>
<p>&nbsp;&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Indemnity</strong>
  </span>
</p>
<p>You agree to defend, indemnify and hold harmless Gold Guys Bullion LLC, and its parents, subsidiaries, affiliates, officers, directors, agents, employees, licensors, members, and shareholders, from any and all claims, expenses, liabilities, losses, actions, damages, or demands, including without limitation reasonable attorneys’ fees, made by any third party directly or indirectly relating to or arising out of: (a) content or information you transmit or obtain&nbsp;</p>
<p>through the transactions, products and/or services contemplated in these Terms and Conditions, (b) your use, purchase, and/or sale of the products and/or services contemplated in these Terms and Conditions, (c) your violation of these Terms and Conditions, (d) your violation of any rights of another, (e) your breach of any representation or warranty made by you to Gold Guys Bullion LLC, or (g) your failure to perform your obligations pursuant to these Terms and Conditions. If you are obligated to provide indemnification pursuant to this provision, Gold Guys Bullion LLC may, in its sole and absolute discretion, control the disposition of any claim at your sole cost and expense. Without limitation of the foregoing, you may not settle, compromise or in any other manner dispose of any claim without Gold Guys Bullion LLC's consent.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Force Majeure</strong>
  </span>
</p>
<p>Any prevention, delay, or stoppage in our performance of any term of these Terms of Purchase or Sale due to strikes, lockouts, labor disputes, acts of God, inability to obtain labor or materials or reasonable substitutes thereof,&nbsp; governmental restrictions, regulations, or controls, the suspension by commodity exchanges in the trading of gold, silver, platinum or palladium&nbsp;</p>
<p>futures contracts or the delivery of the commodities underlying such contracts, or the failure or delay of vendors, enemy or hostile governmental action, riot, civil commotion, fire or other casualties, pandemic, and other causes beyond our control, shall excuse our performance, and extend our performance of our duties and responsibilities hereunder, for a period equal to any such prevention, delay or stoppage. Gold Guys Bullion LLC SHALL NOT HAVE ANY LIABILITY&nbsp;</p>
<p>TO YOU, WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER FORM OF LIABILITY, FOR FAILING TO PERFORM ITS OBLIGATIONS UNDER THESE TERMS AND CONDITIONS TO THE EXTENT THAT SUCH FAILURE IS AS A RESULT OF A FORCE MAJEURE EVENT.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Complete Agreement</strong>
  </span>
</p>
<p>These Terms and Conditions, together with the Privacy Policy and any relevant purchase order, constitute the complete and final expression of the agreement between you and Gold Guys Bullion LLC relating to the purchase and/or sale of bullion and supersedes all prior or contemporaneous contracts, agreements, understandings, and representations, either oral or written, relating to the purchase and/or sale of bullion.&nbsp;</p>
<p>&nbsp;&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Costs / Attorney Fees</strong>
  </span>
</p>
<p>If we are required to retain an attorney to enforce our rights hereunder, we shall be entitled to recover all reasonable costs, expenses, and attorney fees in the event that we prevail in such action.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Disclaimer of Warranties</strong>
  </span>
</p>
<p>All investments, including coins and bullion, involve some degree of risk and are affected by numerous economic factors, all of which are beyond our control. You are responsible for such risk, including, without limitation, market volatility and the inability to liquidate bullion products at an acceptable price, or at all. You represent and warrant to Gold Guys Bullion LLC that you have sufficient experience and knowledge to make informed financial decisions and that Gold&nbsp;</p>
<p>&nbsp;&nbsp;</p>
<p>Guys Bullion LLC is not making any recommendations with respect to bullion purchases&nbsp;</p>
<p>and/or sales.&nbsp;</p>
<p>Gold Guys Bullion LLC MAKES NO WARRANTIES WITH RESPECT TO BULLION PURCHASES AND SALES OTHER THAN THOSE EXPRESS WARRANTIES&nbsp;</p>
<p>CONTAINED IN THESE TERMS AND CONDITIONS, AND HEREBY DISCLAIMS ANY AND ALL IMPLIED WARRANTIES OF EVERY KIND AND NATURE, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTY OF MERCHANTABILITY AND ANY IMPLIED WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE.&nbsp;</p>
<p>Gold Guys Bullion LLC IS A RETAIL PROVIDER, AND DOES NOT ASSUME&nbsp;</p>
<p>RESPONSIBILITY FOR ANY ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DESTRUCTION OF IDENTITY, UNAUTHORIZED ACCESS TO AN ACCOUNT, OR ALTERATION THEREOF, IN RELATION TO ITS WEBSITE, AND RESERVES THE RIGHT TO DISCONTINUE THE WEBSITE AT ANY TIME. Gold Guys Bullion LLC’s WEBSITE MAY CONTAIN FORWARD-LOOKING STATEMENTS THAT REFLECT Gold Guys Bullion LLC’s CURRENT EXPECTATIONS REGARDING FUTURE EVENTS AND BUSINESS DEVELOPMENT, AND THAT INVOLVE RISKS AND UNCERTAINTIES. YOU ACKNOWLEDGE AND AGREE THAT ACTUAL DEVELOPMENTS COULD DIFFER MATERIALLY FROM THOSE PROJECTED AND DEPEND ON A NUMBER OF FACTORS, MOST, IF NOT ALL OF WHICH, ARE OUTSIDE OF Gold Guys Bullion LLC’s CONTROL, AND THAT IT IS ADVISABLE TO CONSULT WITH YOUR INVESTMENT AND FINANCIAL ADVISOR PRIOR TO PURCHASING OR SELLING BULLION.&nbsp;</p>
<p>Some jurisdictions do not allow the disclaimer of implied warranties. In such jurisdictions, the disclaimers above may not apply to you insofar as they relate to implied warranties.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Assignment</strong>
  </span>
</p>
<p>We may assign or transfer any of our rights, duties, and responsibilities under these Terms and Conditions, or any interest therein, without notice to you. You may not assign or transfer any of your rights, duties, and/or responsibilities hereunder without our prior written consent (which may be withheld by us in our sole and absolute discretion), and in such event, you shall not be relieved of any liability under these Terms and Conditions as a result of such assignment or transfer.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Binding Effect</strong>
  </span>
  <strong></strong>
</p>
<p>Each and every provision of these Terms and Conditions shall be binding upon and inure to the benefit of each of us, and our respective heirs, personal representatives, successors and assigns, subject at all times to all provisions and restrictions elsewhere in these Terms of Purchase of Sale. Except as expressly provided herein, nothing in these Terms and Conditions is intended to confer on any person, other than us and our respective heirs, personal&nbsp;</p>
<p>representatives, successors, and assigns, any rights or remedies under or by reason of these Terms and Conditions.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Invalid Provisions</strong>
  </span>
</p>
<p>If any provision of these Terms and Conditions, or the applicability of any such provision to a specific situation, shall be held invalid or unenforceable by a court or adjudicating body of competent jurisdiction, such provision shall be modified to the minimum extent necessary to preserve the intent and spirit of these Terms and Conditions, and to make it or its application valid and enforceable, and the validity and enforceability of all other provisions of these&nbsp;</p>
<p>Terms and Conditions and all other applications of any such provision shall not be affected thereby.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Time of Essence</strong>
  </span>
</p>
<p>Time is of the essence with respect to these Terms and Conditions.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Statute of Limitations</strong>
  </span>
</p>
<p>All issues relating to an order or account must be brought forward to us within five (5) days from the shipment date. This includes, but is not limited to: damaged product or shipment, late delivery, missing or incorrect items, and credit or coupons for late or combined shipments.&nbsp;</p>
<p>
  <span className="underlineTitle">
    <strong>Modification of Terms</strong>
  </span>
</p>
<p>WE RESERVE THE RIGHT TO MAKE CHANGES TO THESE TERMS AND CONDITIONS AT ANY TIME, AND YOU AGREE THAT WE MAY CHANGE THESE TERMS AT ANY TIME. YOU AGREE FURTHER THAT WE MAY MAKE CHANGES TO OUR WEBSITE AND BULLION PURCHASE AND SALE PROCESS AT ANY TIME IN OUR SOLE DISCRETION, FOR ANY&nbsp;</p>
<p>REASON OR NO REASON, AND WITHOUT PRIOR NOTICE TO YOU. YOU AGREE THAT IF YOU CONTINUE TO PURCHASE OR SELL BULLION TO US OR USE OUR WEBSITE AFTER WE HAVE POSTED A CHANGE TO THESE TERMS AND CONDITIONS, THEN YOU ARE BOUND BY THE MOST RECENT VERSION OF THESE TERMS AND CONDITIONS.&nbsp;</p>
<p>ADDITIONAL TERMS, INCLUDING WITHOUT LIMITATION WITH RESPECT TO PRICING, PAYMENT METHODS, REFUND AND CANCELLATION POLICIES, AND EXCHANGE POLICIES MAY BE POSTED FROM TIME TO TIME ON OUR WEBSITE; WHERE SO POSTED, SUCH TERMS AND/OR POLICIES FORM PART OF THESE TERMS AND CONDITIONS.&nbsp;</p>
        </div>
    )

}

import React, { createContext, useContext, useState } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";
import { SpotContext } from '../../providers/SpotProvider';
import SpotTicket from './SpotTicket.tsx';
import { CircularProgress } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function CacheSpots(props){
    const {priceS,priceG,priceP, setPriceS,setPriceG,setPriceP } = useContext(SpotContext);

    const functions = getFunctions();
    const [loading, setLoading] = React.useState(true);
    const [loadingSpot, setLoadingSpot] = React.useState(true);

    const [silverDiff, setSilverDiff] = React.useState(0);
    const [platDiff, setplatDiff] = React.useState(0);
    const [goldDiff, setGoldDiff] = React.useState(0);



  
    React.useEffect(() => {
        const addMessage = httpsCallable(functions, 'fetchSpotCache');
        addMessage()
        .then((result) => {
            console.log("SPOTS")
            console.log(result)
            setPriceS(parseFloat(result.data.spotAG))
            setPriceG(parseFloat(result.data.spotAU))
            setPriceP(parseFloat(result.data.spotPT))
            setSilverDiff(parseFloat(result.data.spotAG) - parseFloat(result.data.spotAGprev))
            console.log("CHECKING")
            console.log(result.data.spotAG)
            console.log(result.data.spotAGprev)

            console.log(parseFloat(result.data.spotAG) - parseFloat(result.data.spotAGprev))

            setplatDiff(parseFloat(result.data.spotPT) - parseFloat(result.data.spotPTprev))
            setGoldDiff(parseFloat(result.data.spotAU) - parseFloat(result.data.spotAUprev))

            setLoading(false)
            setLoadingSpot(false)

        }).catch((err)=>{
            console.log(err)
        })
    }, []);

    if(loadingSpot){
        return(
          <div style={{background:'transparent',margin:'10px auto',textAlign:'center',display:'flex'}}>
            <CircularProgress/> <div style={{background:'transparent',marginTop:10,marginLeft:30}}>Loading real time spot.</div>
          </div>
        )
      }else{

    return (
        <div className='fb fgrey sa w100'>
            {loading ? <></> :<>
        <div className='fb fgrey'>
            <div className='ma fgrey'>Silver</div>
            <div className='fb fgrey headerSpot'>
                <div className="ma fgrey" style={{marginLeft:10,fontSize:'large',fontWeight:'bold'}}>${parseFloat(priceS).toFixed(2)}</div>
                { silverDiff >= 0 ?
                <div className='fgrey ma greenSVG'>
                <KeyboardArrowUpIcon className='greenSVG'/>
                ${parseFloat(silverDiff).toFixed(2)}
                </div>
                :
                <div className='fgrey ma redSVG'>
                <KeyboardArrowDownIcon className='redSVG'/>
                -${parseFloat(silverDiff).toFixed(2)}
                </div>
                }
            </div>
        </div>
        <div className='fb fgrey'>
        <div className='ma fgrey'>Gold</div>
        <div className='fb fgrey headerSpot'>
            <div className="ma fgrey" style={{marginLeft:10,fontSize:'large',fontWeight:'bold'}}>${parseFloat(priceG).toFixed(2)}</div>
            { goldDiff >= 0 ?
                <div className='fgrey ma greenSVG'>
                <KeyboardArrowUpIcon className='greenSVG'/>
                ${parseFloat(goldDiff).toFixed(2)}
                </div>
                :
                <div className='fgrey ma redSVG'>
                <KeyboardArrowDownIcon className='redSVG'/>
                -${parseFloat(goldDiff).toFixed(2)}
                </div>
                }
            </div>
    </div>
    <div className='fb fgrey'>
    <div className='ma fgrey'>Platinum</div>
    <div className='fb fgrey headerSpot'>

        <div className="ma fgrey" style={{marginLeft:10,fontSize:'large',fontWeight:'bold'}}>${parseFloat(priceP).toFixed(2)}</div>
        { platDiff >= 0 ?
                <div className='fgrey ma greenSVG'>
                <KeyboardArrowUpIcon className='greenSVG'/>
                ${parseFloat(platDiff).toFixed(2)}
                </div>
                :
                <div className='fgrey ma redSVG'>
                <KeyboardArrowDownIcon className='redSVG'/>
                -${parseFloat(platDiff).toFixed(2)}
                </div>
                }
            </div>
    </div>
    </>
    }
    </div>
    )
    }
}

import React, { useContext, useRef } from 'react';
import SearchDisplayComonent from '../MainComponents/SearchDisplayComponent.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, CircularProgress, Snackbar, TextField } from '@mui/material';
import { addItemToCart } from '../SmallComponents/PLPCard.tsx';
import { getFunctions, httpsCallable } from "firebase/functions";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Helmet} from "react-helmet";
import { SpotContext } from '../../providers/SpotProvider.jsx';
import styled from "styled-components";
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import ReccomendDisplay from './ReccomendDisplay.tsx';

const algoliasearch = require("algoliasearch");

const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_APP_SEARCH_KEY);
const index = client.initIndex('items');

export default function ProductPage() {
    const functions = getFunctions();
    const nav = useNavigate()
    let { state } = useLocation();
    const location = useLocation();
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const [currentMainImg, setCurrentMainImage] = React.useState("");
    const [attributes, setAttributes] = React.useState([]);
    const [additionalImages, setAdditionalImages] = React.useState([]);
    const [price, setPrice] = React.useState(0);
    const [itemId, setItemId] = React.useState(1);
    const [qty, setQty] = React.useState(1);
    const [hasItem, setHasItem] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [name, setName] = React.useState("");
    const [sDesc, setShortD] = React.useState("");
    const [lDesc, setLDesc] = React.useState("");
    const [priceBookType, setPriceBookType] = React.useState("unit");
    const [inv, setInv] = React.useState("");
    const [buyBack,setBuyBack] = React.useState(0)
    const [priceBookRate,setPriceBookRate] = React.useState(1)
    const [itemContentWeight,setItemContentWeight] = React.useState(1)
    const [category,setCategory] = React.useState("")
    const [category2,setCategory2] = React.useState("")
    const [category3,setCategory3] = React.useState("")
    const [topQTY,setTopQTY] = React.useState(1500)
    const {priceS,priceG,priceP } = useContext(SpotContext);
    const [quantityDisc,setQuantityDisc] = React.useState("")
    const [priceBookElemnts,setPriceBookElements] = React.useState([])
    const [cartContainsItem, setCartContainsItem] = React.useState(false);
    const [isDealZone, setDealZone] = React.useState(false);
    const [categoryString, setCategoryString] = React.useState("");


    function goToCart(){
      nav('/cart')
    }
    React.useEffect(()=>{
      console.log("fetching state data product page")
      console.log(state)
      if(state == undefined || state?.props == undefined || state == null){
        console.log("IN 1 ")
        // fetch alg
        let name = window.location.pathname.replace("/product/",'').replaceAll("+"," ").replace("12","")
        console.log(name)
        index
        .search(name)
        .then(({ hits }) => {
          console.log("HIT")
          console.log(hits)
          let item = hits[0]
          setName(item.name)
          setCurrentMainImage(item.mainImage)
          setAttributes(item.attributes)
          var additionalImageslist = item.additionalImages;
          if(additionalImageslist != undefined && additionalImageslist != null){
            additionalImageslist = [item.mainImage, ...additionalImageslist]
          }
          setAdditionalImages(additionalImageslist)
          setPrice(item.price)
          setItemId(item.objectID)
          setShortD(item.descShort)
          setLDesc(item.descLong)
          setInv(parseFloat(item.stock).toFixed(0))
          setBuyBack(item?.customField?.cf_buyback_rate)
          setPriceBookType(item.priceBookType)
          setPriceBookRate(item.priceBookRate)
          setItemContentWeight(item?.customField?.cf_silver_content)
          setQuantityDisc(item?.customField?.cf_quantity_discount)
          setCategory(item.category)
          setCategory2(item.category2)
          setCategory3(item.category3)
          setDealZone(item.dealitem == "true")

        })
        .catch(err => {
            console.log(err);
        });
        return
      }else{
        console.log("l2")
        console.log(state.props?.item)
        setName(state.props?.item.name)
        setCurrentMainImage(state.props?.item.mainImage)
        setAttributes(state.props?.item.attributes)
        var additionalImageslist = state.props?.item.additionalImages;
        if(additionalImageslist != undefined && additionalImageslist != null){
          additionalImageslist = [state.props?.item.mainImage, ...additionalImageslist]
        }
        setAdditionalImages(additionalImageslist)
        setPrice(state.props?.item.price)
        setItemId(state.props?.item.objectID)
        setShortD(state.props?.item.descShort)
        setLDesc(state.props?.item.descLong)
        setInv(parseFloat(state.props?.item.stock).toFixed(0))
        setBuyBack(state.props?.item?.customField?.cf_buyback_rate)
        setQuantityDisc(state.props?.item?.customField?.cf_quantity_discount)
        setPriceBookType(state.props?.item.priceBookType)
        setPriceBookRate(state.props?.item.priceBookRate)
        let priceBookInfo = state.props?.item.priceBookRate
        setPriceBookRate(priceBookInfo)
        setItemContentWeight(state.props?.item?.customField?.cf_silver_content)
        setCategory(state.props?.item.category)
        setCategory2(state.props?.item.category2)
        setCategory3(state.props?.item.category3)
        setDealZone(state.props?.item.dealitem == "true")

        if(state.props?.item?.customField?.cf_quantity_discount){
          let maxQty = fetchMaxQTY(state.props?.item?.customField?.cf_quantity_discount)
          setTopQTY(maxQty)
        }
        const items = JSON.parse(localStorage.getItem('cart')) || [];
        for(let i = 0; i < items.length; i++){

          if(items[i].item.zohoId == state.props?.item.objectID){
            setCartContainsItem(true)
          }
        }
      }

    },[state?.props])

    React.useEffect(()=>{
      setCategoryString("/"+category+"/"+category2+"/"+category3)

    },[category])
    console.log("CURRENT NAME ")


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
  
    const delay = ms => new Promise(res => setTimeout(res, ms));

    // Create a function to update the screenWidth state
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
  
    React.useEffect(() => {
      // Add an event listener for the "resize" event
      window.addEventListener('resize', updateScreenWidth);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', updateScreenWidth);
      };
    }, []);
    const [open, setOpen] = React.useState(false);
    const handleClick = async () => {
      // add item too cart
      setCartContainsItem(true)
      let rez = await addItemToCart({...state.props,qty:qty})
      if(rez != false){
        setOpen(true);
        await delay(3000);
        setOpen(false)
      }


    };
  
    function fetchMaxQTY(quantityDiscount){
      if(quantityDiscount == "1/10"){
        return 5000
      }
    
      if(quantityDiscount == "1/4"){
        return 2000
      }
      if(quantityDiscount == "1/2"){
        return 1000
      }
      if(quantityDiscount == "1-25"){
        return 1500
      }
    
      if(quantityDiscount == "1-20"){
        return 1500
      }
      if(quantityDiscount == "2"){
        return 250
      }
      if(quantityDiscount == "5"){
        return 50
      }
      if(quantityDiscount == "10"){
        return 50
      }
      if(quantityDiscount == "32"){
        return 30
      }
      if(quantityDiscount == "100"){
        return 15
      }
      return 200
    }
    function getDiscountPriceTierNumber(quantityDiscount){
      if(quantityDiscount == "1/10"){
        return 4
      }
    
      if(quantityDiscount == "1/4"){
        return 4
      }
      if(quantityDiscount == "1/2"){
        return 4
      }
      if(quantityDiscount == "1-25"){
        return 4
      }
    
      if(quantityDiscount == "1-20"){
        return 4
      }
      if(quantityDiscount == "2"){
        return 4
      }
      if(quantityDiscount == "5"){
        return 4
      }
      if(quantityDiscount == "10"){
        return 4
      }
      if(quantityDiscount == "32"){
        return 3
      }
      if(quantityDiscount == "100"){
        return 2
      }
      return 200
    }
    
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ p: 3 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
      function a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
    React.useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant'
        })
        let currentCart = JSON.parse(String(localStorage.getItem("cart")))
        if(currentCart != undefined && currentCart != null){
        var currentItem = currentCart.filter(function(itm){
            return itemId == itm.item.objectID;
          });
        if(currentItem.length != 0){
            setHasItem(true)
        }else{
            setQty(1)
        }
    }
      }, [])
      function replaceDashWithBreak(string) {
        if(string != undefined && string != null){
        const stringWithBreaks = string.split('_').join('<br/><br/>');
        return <div dangerouslySetInnerHTML={{ __html: stringWithBreaks }} />;
        }
      }

      var singleRowPrice
      var buyBackPrice = 0
      let spotPrice = 1

      if(category == "Supplies" || category == "Collectibles"){
        singleRowPrice = parseFloat((priceBookRate )).toFixed(2)
      }else{
        switch(category){
          case "Gold":
            spotPrice = priceG
            if(buyBack != undefined){
              buyBackPrice = buyBack*(itemContentWeight*priceG)
            }
            break;
          case "Silver":
            spotPrice = priceS
            if(buyBack != undefined){
              buyBackPrice = buyBack*(itemContentWeight*priceS)
            }
            break;
          case "Platinum":
            spotPrice = priceP
            if(buyBack != undefined){
              buyBackPrice = buyBack*(itemContentWeight*priceP)
            }
            break;
        }

        let calcPriceRate = 1
        if(priceBookRate.length >= 1){
          calcPriceRate = priceBookRate[0].pricebook_rate
        }else{
          calcPriceRate = priceBookRate
        }
        if(calcPriceRate == undefined){
          calcPriceRate = 1
        }
        

        singleRowPrice = parseFloat((itemContentWeight * (spotPrice + calcPriceRate ))).toFixed(2)
      }
      var singleRowPriceServiceFee = ((parseFloat(singleRowPrice) *.04) + parseFloat(singleRowPrice)).toFixed(2)
      let paypalSingleRowPrice = ((parseFloat(singleRowPrice) *.039) + parseFloat(singleRowPrice)).toFixed(2)
      let cardSingleRowPrice = (((parseFloat(singleRowPrice) *.029) + .3) + parseFloat(singleRowPrice)).toFixed(2)

      function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
      // create priceteirs
      React.useEffect(() => {
        if(priceBookType != "unit"){
        const elements = [];
      let priceTierLevel = getDiscountPriceTierNumber(quantityDisc)
      for (let i = 0; i < priceTierLevel; i++) {

      let discountValue = getSilverDisc(category,category2,category3,i,quantityDisc)
        let discountNumbers = getQuantityNumber(quantityDisc,i)

        let volPriceVal = priceBookRate
        if(priceBookRate != 1){
        volPriceVal = priceBookRate[0].pricebook_rate
        }

        var rowPrice = 0
        if(discountValue == 1){
          rowPrice = parseFloat((itemContentWeight * (priceS + volPriceVal ))).toFixed(2)
        }else{
          rowPrice = parseFloat((itemContentWeight * (priceS + volPriceVal ))-(itemContentWeight * (priceS + volPriceVal ))*discountValue).toFixed(2)
        }

        var rowPriceServiceFee = ((parseFloat(rowPrice) *.04) + parseFloat(rowPrice)).toFixed(2)
        let paypalSingleRowPrice = ((parseFloat(rowPrice) *.039) + parseFloat(rowPrice)).toFixed(2)
        let cardSingleRowPrice = (((parseFloat(rowPrice) *.029) + .3) + parseFloat(rowPrice)).toFixed(2)

        elements.push(<tr>
                <td className='qtycol'>
                    {discountNumbers}
                </td>
                <td>
                ${rowPrice}
                </td>
                <td>
                ${rowPrice}
                </td>
                <td>
                ${rowPrice}
                </td>
                <td>
                ${paypalSingleRowPrice}
                </td>
                <td>
                ${paypalSingleRowPrice}
                </td>
            </tr>)
      }
      setPriceBookElements(elements)
    }
    },[priceBookRate,spotPrice])
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "Product",
      "name": name,
      "image": currentMainImg,
      "description": sDesc,
      "sku": itemId,
      "offers": {
        "@type": "Offer",
        "url": window.location.href,
        "priceCurrency": "USD",
        "price": singleRowPrice,
      }
    };
  let imageHeight = screenWidth/2

  return (
    <div className='defPageMarg' itemType="https://schema.org/Product" itemScope>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{name}</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content={sDesc}/>
            <meta name="keywords" content="Silver, Gold, Bullion, Bars, Coins"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <script type="application/ld+json">
              {JSON.stringify(structuredData)}
            </script>
        </Helmet>
        <div className='fb fw'>
            <div className='pdptopcardcontain'>
              <div>
                <InnerImageZoom 
                  src={currentMainImg}
                  zoomSrc={currentMainImg}
                  zoomScale={2} 
                  zoomType="hover" 
                  width="300px"
                  height="300px"
                  hideHint="true"
                />
              </div>
                <br/>
                <div className='fb fw'>
                    {
                        additionalImages && additionalImages.map((item,index)=>{
                            return(
                            <div style={{cursor:'pointer'}} onClick={()=>{setCurrentMainImage(item)}}>
                                <img src={item} style={{height:'100px',borderRadius:15,marginRight:5}} alt={name}/>
                            </div>
                            )
                        })
                    }
                    
                </div>
            </div>
            <div>

            </div>
            <div className='pdptopcardcontain' style={{margin:'auto'}}>
                <div>
                    <h1 itemprop="name" style={{marginTop:40}}>{name}</h1>
                </div>
                {
                  isDealZone && <div className='dealzone2'>DEAL ZONE </div>
                }
                <div className='pricetable'>
                  {priceS == 0 || priceG == 0 || priceP == 0 ? <>
                    <div>
                    <CircularProgress/>
                    </div>
                  </>:
                  <>
                  {priceBookType == "unit" || quantityDisc == "unit" || quantityDisc == "null" ? 
                                  <table style={{width:"100%",marginBottom:15}}>
                                  <tr>
                                      <th className='qtycol'>QTY</th>
                                      <th>ECheck</th>
                                      <th>Zelle</th>
                                      <th>Wire</th>
                                      <th>Card</th>
                                      <th>PayPal</th>
                                  </tr>
                                          <tr>
                                              <td>
                                                1+
                                              </td>
                                              <td>
                                              ${singleRowPrice}
                                              </td>
                                              <td>
                                              ${singleRowPrice}
                                              </td>
                                              <td>
                                              ${singleRowPrice}
                                              </td>
                                              <td>
                                              ${paypalSingleRowPrice}
                                              </td>
                                              <td>
                                              ${paypalSingleRowPrice}
                                              </td>
                                          </tr>
                              </table>
                  :
                <table style={{width:"100%",margin:'auto'}}>
                    <tr>
                        <th className='qtycol'>QTY</th>
                        <th>ECheck</th>
                        <th>Zelle</th>
                        <th>Wire</th>
                        <th>Card</th>
                        <th>PayPal</th>
                    </tr>
                    {priceBookElemnts}
                </table>
                }
                </>
                  }
                </div>
                <div>*Payment method must be selected during the checkout process</div>
                {priceBookType == "volume"  && quantityDisc !='unit' && quantityDisc
                != "null" &&<div>*{topQTY}+ Please Call <a href="tel:651-846-9710">651-846-9710</a></div>}
                <br/>
                <div className='fb jc pdpaddspacing'>
                {parseFloat(inv) > 0 ? 
                    <>
                    <div className='fb'>
                    <TextField label={"QTY"} style={{width:60,marginRight:10,width:100}} value={qty} type='number' inputProps={{ min: 1 }}
                    onInput={(e)=>{
                        setQty(e.target.value)
                    }}
                    />
                    </div>

                    <Button variant="contained" onClick={()=>{handleClick()}}>Add To Cart</Button>
                    {open ? 
                      <>
                        <Alert variant="filled" severity="success" className='addNotify'>
                        Item Added
                      </Alert>
                      <Button variant='outlined' onClick={()=>{
                        goToCart()
                      }}>Cart</Button>
                      </>
                      :<></>}</>
                      :
                      <div>
                        Out of stock
                      </div>}
                </div>
                
            </div>
        </div>
        <div>
        <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Description" {...a11yProps(0)} className={value == 0 ? "selectedSpect" : ""}/>
          <Tab label="Specs" {...a11yProps(1)} className={value == 1 ? "selectedSpect" : ""}/>
          <Tab label="Shipping" {...a11yProps(1)} className={value == 2 ? "selectedSpect" : ""}/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} itemprop="description">
        {replaceDashWithBreak(lDesc)}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      <div className='fb jc'>
        <table className='specTable'>
      {attributes && attributes.map((item, index) => {
                            const key = Object.keys(item)[0];
                            const value = item[key];
                        return(
                                    <tr className='fb specRow'>
                                        <td style={{width:200,textAlign:'left'}}>{capitalizeFirstLetter(key)}</td>
                                        <td style={{width:'50%'}}>{value}</td>
                                    </tr>
                    )})}
          </table>
                    </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
          <div>
          We make sure that your purchase is handled with the utmost of detail and care until the moment it reaches your hands.<br/><br/>
          Your order will be packed within 72 hours of cleared payment.<br/><br/>
          <b>FREE SHIPPING OFFER!</b><br/>
          All orders over $199 gives you free shipping! Orders under $199 ship for only $9.95<br/><br/>
          <b>Transit Carriers</b><br/>
          We ship via United States Postal Service and FedEx (large shipments). To ensure your complete confidence, every package we ship is fully insured and tracked. A signature is required upon delivery for packages more than $500.<br/><br/>
          <b>INSURANCE</b><br/>
          Every package we ship is fully insured. Please remember to notify us of damage, tampering, or delivery issues within 24 hours of the signature or delivery date. Failure to notify us within this timeframe may impact our ability to cover your claim.<br/><br/>
          </div>
      </CustomTabPanel>
    </Box>
    <div>
      <ReccomendDisplay categoryList={categoryString}/>
    </div>
        </div>
    </div>
  );
}
export function getQuantityNumber(quantityDiscount,index){
  if(quantityDiscount == "1/10" && index == 0){
    return "1-49"
  }
  if(quantityDiscount == "1/10" && index == 1){
    return "50-499"
  }
  if(quantityDiscount == "1/10" && index == 2){
    return "500-999"
  }
  if(quantityDiscount == "1/10" && index == 3){
    return "1000-4999"
  }

  if(quantityDiscount == "1/4" && index == 0){
    return "1-19"
  }
  if(quantityDiscount == "1/4" && index == 1){
    return "20-99"
  }
  if(quantityDiscount == "1/4" && index == 2){
    return "100-499"
  }
  if(quantityDiscount == "1/4" && index == 3){
    return "500-1999"
  }

  if(quantityDiscount == "1/2" && index == 0){
    return "1-19"
  }
  if(quantityDiscount == "1/2" && index == 1){
    return "20-99"
  }
  if(quantityDiscount == "1/2" && index == 2){
    return "100-499"
  }
  if(quantityDiscount == "1/2" && index == 3){
    return "500-999"
  }

  if(quantityDiscount == "1-25" && index == 0){
    return "1-24"
  }
  if(quantityDiscount == "1-25" && index == 1){
    return "25-99"
  }
  if(quantityDiscount == "1-25" && index == 2){
    return "100-499"
  }
  if(quantityDiscount == "1-25" && index == 3){
    return "500-1499"
  }

  if(quantityDiscount == "1-20" && index == 0){
    return "1-19"
  }
  if(quantityDiscount == "1-20" && index == 1){
    return "20-99"
  }
  if(quantityDiscount == "1-20" && index == 2){
    return "100-499"
  }
  if(quantityDiscount == "1-20" && index == 3){
    return "500-1499"
  }
  if(quantityDiscount == "2" && index == 0){
    return "1-9"
  }
  if(quantityDiscount == "2" && index == 1){
    return "10-49"
  }
  if(quantityDiscount == "2" && index == 2){
    return "50-249"
  }
  if(quantityDiscount == "2" && index == 3){
    return "250+"
  }
  
  if(quantityDiscount == "5" && index == 0){
    return "1-9"
  }
  if(quantityDiscount == "5" && index == 1){
    return "10-19"
  }
  if(quantityDiscount == "5" && index == 2){
    return "20-49"
  }
  if(quantityDiscount == "5" && index == 3){
    return "50+"
  }

  if(quantityDiscount == "10" && index == 0){
    return "1-9"
  }
  if(quantityDiscount == "10" && index == 1){
    return "10-19"
  }
  if(quantityDiscount == "10" && index == 2){
    return "20-49"
  }
  if(quantityDiscount == "10" && index == 3){
    return "50+"
  }

  if(quantityDiscount == "32" && index == 0){
    return "1-4"
  }
  if(quantityDiscount == "32" && index == 1){
    return "5-14"
  }
  if(quantityDiscount == "32" && index == 2){
    return "15-29"
  }

  if(quantityDiscount == "100" && index == 0){
    return "1-4"
  }
  if(quantityDiscount == "100" && index == 1){
    return "5-14"
  }


  return ''
}

export function getSilverDisc(category,category2,category3,index,quantityDiscount){
  var discountValue = 1
  if(quantityDiscount == null){
    return 1
  }
  // Fractional
  if((quantityDiscount == "1/10" || quantityDiscount == "1/4" || quantityDiscount == "1/2")  && index == 0){
    return 1
  }
  if((quantityDiscount == "1/10" || quantityDiscount == "1/4" || quantityDiscount == "1/2")  && index == 1){
    return .0075
  }
  if((quantityDiscount == "1/10" || quantityDiscount == "1/4" || quantityDiscount == "1/2")  && index == 2){
    return .015
  }
  if((quantityDiscount == "1/10" || quantityDiscount == "1/4" || quantityDiscount == "1/2")  && index == 3){
    return .0225
  }

  //Rounds 
  if((quantityDiscount == "1-20" || quantityDiscount == "1-25") && index == 0){
    return 1
  }
  if((quantityDiscount == "1-20" || quantityDiscount == "1-25") && index == 1){
    return .0075
  }
  if((quantityDiscount == "1-20" || quantityDiscount == "1-25") && index == 2){
    return .015
  }
  if((quantityDiscount == "1-20" || quantityDiscount == "1-25") && index == 3){
    return .0225
  }

  // oz rounds 
  if((quantityDiscount == "2" || quantityDiscount == "5" || quantityDiscount == "10") && index == 0){
    return 1
  }
  if((quantityDiscount == "2" || quantityDiscount == "5" || quantityDiscount == "10") && index ==  1){
    return .0075
  }
  if((quantityDiscount == "2" || quantityDiscount == "5" || quantityDiscount == "10") && index ==  2){
        return .015
  }
  if((quantityDiscount == "2" || quantityDiscount == "5" || quantityDiscount == "10") && index ==  3){
        return .0225
  }

  // Kilos 
  if(quantityDiscount == "32" && index == 0){
    return 1
  }
  if(quantityDiscount == "32" && index == 1){
    return .0075
  }
  if(quantityDiscount == "32" && index == 2){
    return .015
  }

  // 100 oz  
  if(quantityDiscount == "100" && index == 0){
    return 1
  }
  if(quantityDiscount == "100" && index == 1){
    return .0075
  }
}
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import * as React from 'react';
import SummaryBlock from '../SmallComponents/SummaryBlock.tsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatPrice } from './CartPage.tsx';
import { useCheckout } from '../../providers/CheckoutProvider.jsx';
import { getFunctions, httpsCallable } from "firebase/functions";
import { TimerBlock } from '../SmallComponents/TImerBlock.tsx';
import { CheckoutForm } from '../SmallComponents/CheckoutContainer.tsx';
import {
  Elements,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Helmet} from "react-helmet";

export default function ReviewCompletePage(props) {
  const [shipAddObj, setShipAddObj] = React.useState({});
  const [finalAddressObject, setFinalAddressObject] = React.useState({});
  const {state} = useLocation();
  const [payMethod, setPayMethod] = React.useState("");
  const { getAddress, getShippingPrice,getCart,setTax,setDisableContinue,setPayTypeObj,getPayTypeObj,payType,getSub,getCartSubTotal,getCartFinalTotal } = useCheckout();
  const functions = getFunctions();
  const [sumUpdate, setSumUpdate] = React.useState(false)
  const [currentComponent, setCurrentComponent] = React.useState('');
  const nav = useNavigate();
  const [loadingTax, setLoadingTax] = React.useState(true)


  const renderComponent = () => {
    switch (currentComponent) {
      case "DLX E-Check":
        return <div><div style={{marginBottom:10}}>*To be paid when Invoice recieved.</div>Please send your eChecks to our established deluxe eCheck account via email at sales@goldguys.com , please fill the memo line with your invoice #.</div>;
      case 'Wire':
        return <div>
        Once your invoice is received you will find the wire instructions attached. Please identify purpose of payment in subject line/memo field by using your invoice #</div>;
      case 'Zelle':
        return <div>Please send Zelle transfers to our established Zelle account at sales@goldguys.com , please fill the memo line with your invoice #.</div>;
      case 'PayPal':
        return <div>Paypal payments are processed during the checkout process on our website. We accept payment for the following cards: VISA, Mastercard, American Express, and Discover. This payment method is subject to processing fees.</div>;
      case 'Credit Card':
          return <div>Payments are processed during the checkout process on our website.  We accept payment for the following cards: VISA, Mastercard, American Express, and Discover. We also accept Google Pay, Apple Pay, and Link. We use Stripe integrations to allow for seamless payment completion on our website during the cart checkout process. This payment method is subject to processing fees.</div>;
      default:
        return <div>Payments are processed during the checkout process on our website.  We accept payment for the following cards: VISA, Mastercard, American Express, and Discover. We also accept Google Pay, Apple Pay, and Link. We use Stripe integrations to allow for seamless payment completion on our website during the cart checkout process. This payment method is subject to processing fees.</div>;
    }
  };

  const handleChange = (event) => {
    setCurrentComponent(event.target.value)
    setPayMethod(event.target.value);
    setPayTypeObj(event.target.value)
  };
  function toFixedNoRounding(num, fixed) {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return parseFloat(num.toString().match(re)[0]);
}
const [cartItems, setCartItems] = React.useState([])

React.useEffect(()=>{
  updateOrderTax()
},[payMethod])
function updateOrderTax(){
  setDisableContinue(true)
  setLoadingTax(true)
  setFinalAddressObject(getAddress()) 
  setShipAddObj(getShippingPrice()) 

  const addMessage = httpsCallable(functions, 'orderTaxCalc');
  addMessage({items:getCart(),finalAddress:getAddress(),payType:payMethod})
  .then((result1) => {
    let taxSummary = 0 
    if(parseFloat(getCartSubTotal()) < 199){
    if(result1.data.response.summary && result1.data.response.summary.length > 0 ){
      for(let i = 0 ; result1.data.response.summary.length > i ; i++ ){
        let takeRate = toFixedNoRounding(result1.data.response.summary[i].rate*10,2)
        taxSummary = taxSummary + takeRate
      }
    }
  }
  console.log("TAX RES: ",result1.data.response)


    let taxR = result1.data.response.totalTax //+ taxSummary
    setTax(taxR)
    localStorage.setItem('taxCalc', JSON.stringify(taxR));
    setLoadingTax(false)
    //setDisableContinue(false)
    setSumUpdate(true)
    localStorage.setItem('lastCartOrder',JSON.stringify(getCart()))

  }).catch((err)=>{
    alert("Tax Error. Please try again later.")
    console.log(err)
  })
}
    React.useEffect(()=>{
      console.log("Payment type : ", payMethod)
      updateOrderTax()
  },[payMethod])



      React.useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant'
        })
      }, [])
      async function getCartItems(){
        setCartItems(getCart())
      }
      React.useEffect(() => {
        setPayTypeObj("")
        setPayMethod("")
        if(getCart().length == 0 ){
          nav("/cart")
        }
        getCartItems()
      }, [])
    return (
        <div className='defPageMarg'>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Order Review</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content="Order Review"/>
            <meta name="keywords" content="Silver, Gold, Bullion, Bars, Coins, Precious Metals"/>
        </Helmet>
            <div className='defaultTitle'>
                Review & Complete
            </div>
            <div className='fb sa'>
            <TimerBlock/>
            </div>
            <br/>
            <div className='fb fw jsb hmarg40'>
                <div className='shipcardcontain'>
                <div className='defaultTitle'>PAYMENT TYPE</div>
                <FormControl fullWidth >
                    <InputLabel id="demo-simple-select-label">Select Payment Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={payMethod}
                        onChange={handleChange}
                        label={"Select Payment Type"}
                    >                       
                        <MenuItem value={"Credit Card"}>Card</MenuItem>
                        <MenuItem value={"DLX E-Check"}>DLX E-Check</MenuItem>
                        <MenuItem value={"Wire"}>Wire</MenuItem>
                        <MenuItem value={"Zelle"}>Zelle</MenuItem>
                        <MenuItem value={"PayPal"}>PayPal</MenuItem>
                    </Select>
                    </FormControl>
                    <div style={{marginBottom:30,marginTop:30}}>
                    {renderComponent()}
                    </div>
                    <div style={{marginBottom:60}}>Visit our <a href="/about" style={{textDecoration:'underline'}}>about page</a> for more information.</div>
    
                <hr/>
                <div className='defaultTitle'>Order Details</div>
                <div style={{marginBottom:5}}>Ship to:</div>
                <div>
                    <b>{finalAddressObject.addrline1} {finalAddressObject.addrline2} {finalAddressObject.addrcity}, {finalAddressObject.addrstate} {finalAddressObject.addrzip}</b>
                </div>
                <br/>
                    <div>
                        <div>
                            <div>
                                Order Items
                            </div>
                            {cartItems.map((row,index) => (
                                <div key={index}>
                                    <hr/>
                                    <div className='fb'>
                                        <div>
                                            <img src={row?.item.mainImage} alt={row.item.name} height="70" width="70" style={{marginRight:15}}/>
                                        </div>
                                        <div>
                                        <div>
                                            {row.item.name}
                                            </div>
                                            <div> <b>${formatPrice(row?.calculatedSinglePrice)} X {row?.qty}</b></div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div className='shipcardcontain'>
                    <SummaryBlock sumUP={sumUpdate} payMeth={payMethod} loadingTax={loadingTax}/>
                </div>
            </div>
        </div>
    )
}
import { Alert, Button, InputLabel, OutlinedInput, Snackbar, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword,sendPasswordResetEmail } from "firebase/auth";

export default function LoginHeader() {
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [error1, setError1] = React.useState(false);
    const [error2, setError2] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const nav = useNavigate();
    const auth = getAuth();
    const handleInputChange = (event, fieldSetter, errorSetter) => {
        const value = event.target.value;
        fieldSetter(value);
        errorSetter(value.trim() === '');
      };
    function loginUser(){
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                // ...
                nav("/myaccount")
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alert(errorMessage)
            });
    }
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    function resetUserPassword(){
        if(email != "" && email != undefined){
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
          .then(() => {
            // Password reset email sent!
            // ..
            alert("An email to reset your passsword has been sent to the provided email.")
            setOpen(true)
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
            alert("A reset password link has been sent for you account.")
          });
        }else{
            alert("Please enter email address to reset the password in the field above.")
        }
    }
    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
          })
      }, [])

    return(
        <div className='minH2'>
       <Snackbar open={open} autoHideDuration={60000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Forgot password link has been sent to your email.
        </Alert>
      </Snackbar>
            <h1 className='tac'>Customer Login</h1>
            <div className='fb fw hmarg40'>
                <div className='h50Box mb60 w100'>
                    <div ><b>Returning Customers</b></div>
                    <div>If you have an account, sign in with your email address.</div>
                    <div>
                        <div>
                            <TextField fullWidth={true} id="outlined-basic" label="Email" variant="outlined" 
                                    value={email} 
                                    onInput={(e) => {
                                        handleInputChange(e,setEmail,setError1)
                                    }}
                                    error={error1}
                                    helperText={error1 ? 'This field is required' : ''}
                                    style={{marginBottom:25,marginTop:10}}
                                    />
                                <TextField fullWidth={true} id="outlined-basic" label="Password" variant="outlined" 
                                type='password'
                                value={password} 
                                onInput={(e) => {
                                    handleInputChange(e,setPassword,setError2)
                                }}
                                />
                            <div className='dmarg fb je fw'>
                                <Button variant="contained" className='goldButton' onClick={()=>{loginUser()}}>Login</Button>
                            </div>
                            <div className='dmarg fb je fw'>
                                <Button variant="contained"  className='goldButton' onClick={()=>{resetUserPassword()}}>Forgot Password</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='h50Box'>
                    <div style={{textAlign:'center'}}><b>New Customers</b></div>
                    <div style={{textAlign:'center'}}>Creating an account has many benefits: check out faster, keep more than one address, track orders and more.</div>
                    <br/>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <Link to="/createaccount">
                        <Button variant="contained"  className='goldButton'>Create New Customer</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )

}

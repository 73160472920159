import { ExpandMore } from '@mui/icons-material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export default function HelpPage() {
  const spotTypes = ["Silver","Gold","Platinum"];

  const helpInfo = [
	{
		tit:"How do payments work?", desc:" At Gold Guys Bullion our top-priority is security, efficiency, and customer satisfaction when it comes to our transaction processes. Gold Guys Bullion does not store any bank account or credit card information on our site, all that information will be stored with our third-party payment processing partners. We offer you, our valued customer, five different payment options during the checkout process; each of these payment options have their advantages and considerations. For more detailed information on the process, security, and convenience of the checkout process please follow this link. <a href='https://www.goldguys.com/contact/'>link</a>",
	},
	{
		tit:"Cancellations",desc:`Gold Guys Bullion requires that you have your payment initiated to us within the 48 hour payment window that begins once you receive an invoice from us. All payments shall be received by Gold Guys in full for your entire order within three business days, an extra day is allowed for the relevant clearing time for receipt to our accounts. If payment is not received within the three business day window, order may be subject to immediate cancellation. \n We will reach out to you after the third business day payment receipt window has expired to inquire about payment status.  Automatic cancellation after 5 business days.
		For further inquiries on Cancellations please refer to our ${<Link to="/terms">Terms of Service</Link>}`
	},
	{
		tit:"Trust & Transparency",desc:"Gold Guys Bullion is a unique buying opportunity that is operating under a multi-decade small business success story that continues to be built on a foundation of customer loyalty, transparency, and satisfaction. The Gold Guys story has been a story of success due to the tremendous level of professionalism, detail-oriented employees, and highly satisfied customers. The tens of thousands of happy customer experiences in transacting with the Gold Guys is the number one measuring-stick in how our business is designed to operate - and that is to service you, our valued customers. Trust is a must. We want you to know and experience that we conduct our business with you in mind, always. We operate competitively with smaller margins to ensure that you are acquiring more physical wealth for less. We have teams on call to help and support you with answers for your questions. Mon - Sat: 10:00AM - 8:00PM , Sunday 11:00AM - 6:00PM (CST Time Zone)"
	},
	{
		tit:"Your Account",desc:"When entering our website there is an icon that says 'Login', click on it. You may create an account (if you do not have one) or sign in (if you already created an account). Once logged in you may enter/edit your shipping address that will be saved for your checkout process. In addition, you will have access to see all orders that you have completed on your account to help you maintain records of your transactions with us. Also, with your account you will have the ability to subscribe to our Gold Guys email list. We highly recommend this because you will then gain access to purchase products from our Deal Zone page. In the event that you have forgotten your password you may request to reset your password by providing the email associated with your account and we will send you an email to reset your password to get you back on track and do what you are here to do, buy precious metals... acquiring real wealth!"
	},
	{
		tit:"Deal Zone",desc:"Because we are already offering very competitive pricing that beats all the competition we will not be having constant deals like you may be accustomed to when shopping online, and that is because we have strategized to have everything on our site to be an awesome deal for you! HOWEVER... we will be offering limited and highly sought after deals in our Deal Zone! Deal Zone is only accessible to purchase for accounts that have subscribed to the Gold Guys Bullion free email subscription list. We will notify you of new deals, new products, market events, and industry insight. Take advantage of our Deal Zone offerings by subscribing to our newsletter below."
    },	
	{
		tit:"Contact Us",desc:"If you would like to contact us please follow this <a href='https://www.goldguys.com/contact/'>link</a> or give us a call at 651-846-9710. If you have questions about your account please email support@goldguys.com"
    }

    ]
	React.useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant'
        })
      }, [])
  return (
    <div className='defPageMarg'>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Help Page</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content="Help Page"/>
            <meta name="keywords" content="Silver, Gold, Bullion, Bars, Coins, Precious Metals"/>
        </Helmet>
        <div style={{padding:30}}>
            <div className='defaultTitle'>
                HELP PAGE
            </div>
        {helpInfo.map((item) => {
          
          if(item.tit == "Contact Us"){
            return(
            <div className="helpAccRoot">
            <Accordion
          className="helpAccRootRoot">
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            className="helpAccSum"
              >
                <Typography className="helpAccHead" inline>{item.tit}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="helpAcclb" >
                If you would like to contact us please follow this <a href="https://www.goldguys.com/contact/" style={{textDecoration:'underline'}}>link</a> or give us a call at <a href="tel: 651-846-9710" style={{textDecoration:'underline'}}>651-846-9710</a>.
                  If you have questions about your account please email <a href="mailto:support@goldguys.com" style={{textDecoration:'underline'}}>support@goldguys.com</a>.
                </Typography>
      
              </AccordionDetails>
            </Accordion>
          </div>)
          }else if(item.tit == "How do payments work?"){
            return(
            <div className="helpAccRoot">
            <Accordion
          className="helpAccRootRoot">
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            className="helpAccSum"
              >
                <Typography className="helpAccHead" inline>{item.tit}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="helpAcclb" >
                At Gold Guys Bullion our top-priority is security, efficiency, and customer satisfaction when it comes to our transaction processes. Gold Guys Bullion does not store any bank account or credit card information on our site, all that information will be stored with our third-party payment processing partners. We offer you, our valued customer, five different payment options during the checkout process; each of these payment options have their advantages and considerations. For more detailed information on the process, security, and convenience of the checkout process please follow this&nbsp;
                <Link to={'/about'} style={{textDecoration:'underline'}}>
                  link
                </Link>.
                </Typography>
      
              </AccordionDetails>
            </Accordion>
          </div>)
          }else if(item.tit == "Cancellations"){
            return(
            <div className="helpAccRoot">
            <Accordion
          className="helpAccRootRoot">
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            className="helpAccSum"
              >
                <Typography className="helpAccHead" inline>{item.tit}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="helpAcclb" >
                Gold Guys Bullion requires that you have your payment initiated to us within the 48 hour payment window that begins once you receive an invoice from us. All payments shall be received by Gold Guys in full for your entire order within three business days, an extra day is allowed for the relevant clearing time for receipt to our accounts. If payment is not received within the three business day window, order may be subject to immediate cancellation. \n We will reach out to you after the third business day payment receipt window has expired to inquire about payment status.  Automatic cancellation after 5 business days.
                For further inquiries on Cancellations please refer to our <Link to="/terms"  style={{textDecoration:'underline'}}>Terms of Service</Link>.
                </Typography>
      
              </AccordionDetails>
            </Accordion>
          </div>)
          }else{
            
          return (
	<div className="helpAccRoot">
      <Accordion
	  className="helpAccRootRoot">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
		  className="helpAccSum"
        >
          <Typography className="helpAccHead" inline>{item.tit}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="helpAcclb" >
				{item.desc}
          </Typography>

        </AccordionDetails>
      </Accordion>
    </div>
	)}})}
          	<div className="helpAccRoot">
            <Link to="https://www.goldguys.com/how-to-sell/" target='_blank'>
            <Accordion
          className="helpAccRootRoot">
              <AccordionSummary
                expandIcon={<ArrowForwardIosIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            className="helpAccSum"
              >
                <Typography className="helpAccHead" inline>Sell To Us</Typography>
              </AccordionSummary>

            </Accordion>
            </Link>
            </div>
        </div>
    </div>
  );
}
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import SummaryBlock from '../SmallComponents/SummaryBlock.tsx';
import { UserContext } from '../../providers/UserProvider.jsx';
import { doc, getDoc,updateDoc } from "firebase/firestore";
import { db } from '../../config.tsx';
import { useNavigate } from 'react-router-dom';
import { getFunctions, httpsCallable } from "firebase/functions";
import PhoneIcon from '@mui/icons-material/Phone';
import { useCheckout } from '../../providers/CheckoutProvider.jsx';
import { TimerBlock } from '../SmallComponents/TImerBlock.tsx';
import {Helmet} from "react-helmet";

export default function ShippingPage() {
    const nav = useNavigate();
    const functions = getFunctions();
    const [name, setName] = React.useState("");
    const [addr1, setAddr1] = React.useState("");
    const [addr2, setAddr2] = React.useState("");
    const [addrCity, setAddrCity] = React.useState("");
    const [addrState, setAddrState] = React.useState("");
    const [addrZip, setAddrZip] = React.useState("");
    const [addrEmail, setAddrEmail] = React.useState("");
    const [addrPhone, setAddrPhone] = React.useState("");
	const user = React.useContext(UserContext);
    const [emailValue, setEmailValue] = React.useState("");
    const [finalAddressObject, setFinalAddressObject] = React.useState({});
    const [finalItemObject, setfinalItemObject] = React.useState({});
    const [addressSelectLoading, setAddressSelectLoading] = React.useState(false);
    const [shippingLabel1, setShippinglabel1] = React.useState("9.95");
    const [shipAddObj, setShipAddObj] = React.useState({});
    const [isRegUser, setIsRegUser] = React.useState(false);
    const [subscribeUser, setSubScribeUser] = React.useState(true);
    const [currentShipAmount, setCurrentShipAmount] = React.useState(0);
    const [hasSelectedAdd, setHasSelectedAddr] = React.useState(false);
    const { setAddress, getCartSubTotal,setShippingWeight,setShippingPrice ,getCart} = useCheckout();
    const [addrLoading, setAddrLoading] = React.useState(true);

    const [shipProvider, setShipProvider] = React.useState("");
    const [shipServiceLevel, setShipServiceLevel] = React.useState("");
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    const [error3, setError3] = useState(false);
    const [error4, setError4] = useState(false);
    const [error5, setError5] = useState(false);
    const [error6, setError6] = useState(false);
    const [error7, setError7] = useState(false);
    const [error8, setError8] = useState(false);
    const [isChecked, setChecked] = useState(true);
    // WEIGHT IN OUNCES  
    const defaultWeightPrice = 9.95
    const handleCheckboxChange = () => {
      setChecked(!isChecked);
    };
    const [shippingSelected, setShippingSelected] = useState(false);
    let shippingPrice = "USPS Priority $9.95"
    if(getCartSubTotal() >= 199){
      shippingPrice = " Free Shipping via USPS Priority Flat Rate. Your order is over $199!"
    }
    if(getCartSubTotal() >= 9999){
        shippingPrice = " Free Shipping via FEDEX, required for orders over $10000."
    }
    
    const formatPhoneNumber = (input) => {
        // Replace all non-digit characters with an empty string
        const cleaned = input.replace(/\D/g, '');
    
        // Apply phone number formatting
        if (cleaned.length <= 3) {
          return cleaned;
        } else if (cleaned.length <= 7) {
          return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
        } else {
          return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
        }
      };
      const handlePhoneNumberChange = (e) => {
        const formattedNumber = formatPhoneNumber(e.target.value);
        setAddrPhone(formattedNumber);
      };
    const [addressUpdated, setAddressUpdated] = React.useState(false);
    async function commitAddressChanges(){
        if(!isValidEmail(addrEmail)){
            alert("Email format is incorrect.")
            return false
        }
        if(name == "" || addrEmail == "" || addrPhone == "" || addr1 == "" || addrCity == "" || stateInputVal == "" || addrZip == ""){
            alert("Please fill out required fields.")
            return false
        }
        setAddressSelectLoading(true)

        //TODO SAVE TO MY ACCOUNT OR DO NOT SAVE OR JUST SHIPID? 
        if(user){
            var addrObj = {
                addrline1: addr1,
                addrline2: addr2,
                addrcity: addrCity,
                addrstate: stateInputVal,
                addrzip: addrZip,
                name:name,
                email:addrEmail,
                phone:addrPhone
            }

            setAddress(addrObj)
            console.log("----")
            console.log(addrObj)
            
            localStorage.setItem("shippingAddress",JSON.stringify(addrObj))
            
            // set customer shipping 
            return await httpsCallable(functions, 'updateCustomerShipping')({addrObj})
            .then((result) => {
                //console.log(result)
                console.log(result)
                if(result.data.Error){
                    alert("Error: " +result.data.Error+'\n\n'+result.data.errorDetails)
                    return false
                }else{
                    let shippingPrice = 9.95
                    if(getCartSubTotal() >= 199){
                      shippingPrice = 0 
                    }
                    console.log("Setting shipping: ",shippingPrice)
                    setShippingPrice(shippingPrice)
                    setHasSelectedAddr(true)
                    setAddressSelectLoading(false)
                    setShippingSelected(true)
                    //alert("address added")
                    return true
                }
            }).catch((err)=>{
                console.log(err)
                alert("Address Error 111")
                return false
            })
        }
        return false

    }
    React.useEffect(()=>{
        console.log("effect setting shipping");
        console.log(getCartSubTotal() >= 199)
          let shippingPrice = 9.95
          if(getCartSubTotal() >= 199){
            shippingPrice = 0 
          }else if(getCartSubTotal() >= 9999){
            shippingPrice = 0 
          }
          setShippingPrice(shippingPrice)
    },[])



    async function fetchUserAddress(userUID) {
        const docRef = doc(db, "users", userUID);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            if(docSnap.data().addrline1){
                setAddr1(docSnap.data().addrline1)
            }
            if(docSnap.data().addrline2){
                setAddr2(docSnap.data().addrline2)
            }
            if(docSnap.data().addrcity){
                setAddrCity(docSnap.data().addrcity)
            }
            if(docSnap.data().addrstate){
                setStateInputValue(docSnap.data().addrstate)
            }
            if(docSnap.data().addrzip){
                setAddrZip(docSnap.data().addrzip)
            }
            if(docSnap.data().name){
                setName(docSnap.data().name)
            }
            if(docSnap.data().email){
                setAddrEmail(docSnap.data().email)
            }
            if(docSnap.data().phone){
                setAddrPhone(docSnap.data().phone)
            }
        } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
        }
        setAddrLoading(false)
        
    }

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    async function updateSavedAdder(){

        if(!isValidEmail(addrEmail)){
            alert("Email format is incorrect.")
            return false
        }
        
        if(name == "" || addrEmail == "" || addrPhone == "" || addr1 == "" || addrCity == "" || stateInputVal == "" || addrZip == ""){
            alert("Please fill out required fields.")
            return false
        }
            var addrObj = {
                addrline1: addr1,
                addrline2: addr2,
                addrcity: addrCity,
                addrstate: stateInputVal,
                addrzip: addrZip,
                name:name,
                phone:addrPhone
            }
            const docRef = doc(db, "users", user.uid);

            // Set the "capital" field of the city 'DC'
            await updateDoc(docRef, addrObj);

            alert("Address Updated!")
    }
    const [stateInputVal, setStateInputValue] = React.useState('');

    const handleChange = (event: any) => {
        setStateInputValue(event.target.value as string);
    };

    React.useEffect(() => {
        //TODO 
        const cartJson = getCart()
        // display weight depending on cart 

		if (user) {
            fetchUserAddress(user.uid)
            //setAddrEmail(user.email)
        }else{
            nav("/cart")
        }
    },[user])
    React.useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
          })
      }, [])

      const handleInputChange = (event, fieldSetter, errorSetter) => {
        const value = event.target.value;
        if(value != ''){
            setAddressUpdated(true);
        }
        fieldSetter(value);
    
        // Check if the input is empty and set the error state accordingly
        errorSetter(value.trim() === '');
      };

    return (
        <div className='defPageMarg'>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Shipping</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content="Shipping Page"/>
            <meta name="keywords" content="Silver, Gold, Bullion, Bars, Coins, Precious Metals"/>
        </Helmet>
            <div className='defaultTitle'>
                Shipping
            </div>
            <div className='fb sa'>
            <TimerBlock/>
            </div>
            <div className='fb fw hmarg40'>
                 <div className='shipcardcontain'>
                    <div className='fb fdc'>
                    <div>
                    <div style={{textAlign:'center'}}>
                        {addrLoading && (<CircularProgress/>)}
                    </div>
                    </div>
                    <div style={{fontSize:'small',margin:'20px 0'}}>* Required</div>
                    <TextField id="outlined-basic" label="Name *" variant="outlined" 
                            focused={true}
                            value={name} 
                            onInput={(e) => {
                                handleInputChange(e,setName,setError6)
                            }}
                            error={error6}
                            helperText={error6 ? 'This field is required' : ''}
                            /><br/>
                        <TextField id="outlined-basic" label="Address Line 1 *" variant="outlined" 
                            focused={true}
                            value={addr1} 
                            onInput={(e) => {
                                handleInputChange(e,setAddr1,setError1)
                            }}
                            error={error1}
                            helperText={error1 ? 'This field is required' : ''}
                            /><br/>
                        <TextField id="outlined-basic" label="Address Line 2" variant="outlined" 
                        value={addr2} 
                        onInput={(e) => {
                            handleInputChange(e,setAddr2,setError2)
                        }}
                        /><br/>
                        <TextField id="outlined-basic" label="City *" variant="outlined"
                        value={addrCity} 
                        onChange={(e) => {
                            handleInputChange(e,setAddrCity,setError3)
                        }}
                        error={error3}
                        helperText={error3 ? 'This field is required' : ''}
                        /><br/>
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">State *</InputLabel>
                                <Select
                                id="demo-simple-select"
                                value={stateInputVal}
                                onChange={handleChange}
                                label="State *"
                                >
<MenuItem value={"AL"}>Alabama</MenuItem>
<MenuItem value={"AK"}>Alaska</MenuItem>
<MenuItem value={"AZ"}>Arizona</MenuItem>
<MenuItem value={"AR"}>Arkansas</MenuItem>
<MenuItem value={"CA"}>California</MenuItem>
<MenuItem value={"CO"}>Colorado</MenuItem>
<MenuItem value={"CT"}>Connecticut</MenuItem>
<MenuItem value={"DE"}>Delaware</MenuItem>
<MenuItem value={"FL"}>Florida</MenuItem>
<MenuItem value={"GA"}>Georgia</MenuItem>
<MenuItem value={"HI"}>Hawaii</MenuItem>
<MenuItem value={"ID"}>Idaho</MenuItem>
<MenuItem value={"IL"}>Illinois</MenuItem>
<MenuItem value={"IN"}>Indiana</MenuItem>
<MenuItem value={"IA"}>Iowa</MenuItem>
<MenuItem value={"KS"}>Kansas</MenuItem>
<MenuItem value={"KY"}>Kentucky</MenuItem>
<MenuItem value={"LA"}>Louisiana</MenuItem>
<MenuItem value={"ME"}>Maine</MenuItem>
<MenuItem value={"MD"}>Maryland</MenuItem>
<MenuItem value={"MA"}>Massachusetts</MenuItem>
<MenuItem value={"MI"}>Michigan</MenuItem>
<MenuItem value={"MN"}>Minnesota</MenuItem>
<MenuItem value={"MS"}>Mississippi</MenuItem>
<MenuItem value={"MO"}>Missouri</MenuItem>
<MenuItem value={"MT"}>Montana</MenuItem>
<MenuItem value={"NE"}>Nebraska</MenuItem>
<MenuItem value={"NV"}>Nevada</MenuItem>
<MenuItem value={"NH"}>New Hampshire</MenuItem>
<MenuItem value={"NJ"}>New Jersey</MenuItem>
<MenuItem value={"NM"}>New Mexico</MenuItem>
<MenuItem value={"NY"}>New York</MenuItem>
<MenuItem value={"NC"}>North Carolina</MenuItem>
<MenuItem value={"ND"}>North Dakota</MenuItem>
<MenuItem value={"OH"}>Ohio</MenuItem>
<MenuItem value={"OK"}>Oklahoma</MenuItem>
<MenuItem value={"OR"}>Oregon</MenuItem>
<MenuItem value={"PA"}>Pennsylvania</MenuItem>
<MenuItem value={"RI"}>Rhode Island</MenuItem>
<MenuItem value={"SC"}>South Carolina</MenuItem>
<MenuItem value={"SD"}>South Dakota</MenuItem>
<MenuItem value={"TN"}>Tennessee</MenuItem>
<MenuItem value={"TX"}>Texas</MenuItem>
<MenuItem value={"UT"}>Utah</MenuItem>
<MenuItem value={"VT"}>Vermont</MenuItem>
<MenuItem value={"VA"}>Virginia</MenuItem>
<MenuItem value={"WA"}>Washington</MenuItem>
<MenuItem value={"WV"}>West Virginia</MenuItem>
<MenuItem value={"WI"}>Wisconsin</MenuItem>
<MenuItem value={"WY"}>Wyoming</MenuItem>

                                </Select>
                            </FormControl>
                            </Box>
                        <br/>
                        <TextField id="outlined-basic" label="Zipcode *" variant="outlined"
                        value={addrZip} 
                        onChange={(e) => {
                            handleInputChange(e,setAddrZip,setError5)
                        }}
                        error={error5}
                        helperText={error5 ? 'This field is required' : ''}
                        /><br/>
                        <TextField id="outlined-basic" label="Email *" variant="outlined"
                        value={addrEmail} 
                        onChange={(e) => {
                            handleInputChange(e,setAddrEmail,setError7)
                        }}
                        error={error7}
                        helperText={error7 ? 'This field is required*' : ''}
                        />
                        <div className='fb' style={{margin:'5px 0px 20px 0px'}}>
                        <FormGroup>
                        <FormControlLabel onChange={()=>handleCheckboxChange()} control={<Checkbox defaultChecked checked={isChecked}/>} label="Get Email Exclusive Deals" />
                        </FormGroup>
                        <div style={{marginLeft:20}}>
                        </div>
                        </div>
                        <TextField id="outlined-basic" label="Phone Number *" variant="outlined" type=''
                        value={addrPhone} 
                        onChange={handlePhoneNumberChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={error8}
                        helperText={error8 ? 'This field is required' : ''}
                        /><br/>

                        <Button onClick={()=>{updateSavedAdder()}} variant='contained'>Save Address</Button>
                    </div>
                </div>
                <div className='shipcardcontain'>
                    <div>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">Delivery Method</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="standard"
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value="standard" control={<Radio />} label={shippingPrice} />

                            </RadioGroup>
                            </FormControl>
                            {getCartSubTotal() > 500 ? <div>*Signature Required</div>:<></>}
                    </div>
                </div>
                <div className='shipcardcontain'>
                    <SummaryBlock disableCont={true} commitAddress={commitAddressChanges}/>
                </div>
            </div>

        </div>
    )
}
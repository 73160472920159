import * as React from 'react';

export default function Privacy() {

    React.useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant'
        })
      }, [])
let html = `
<div style="text-align:center;font-size:large;margin-bottom:20px">
  <strong>PRIVACY POLICY</strong>
</div>
<p>
  <em>Last updated: July 8, 2024</em>
</p>
<p>This Privacy Policy governs the privacy practices for Gold Guys Bullion, LLC, a Minnesota limited liability company and its affiliates (if any) (“<span style="color:rgb(255, 0, 0);">
    <strong>GGB</strong>
  </span>,” “<strong>we</strong>,” “<strong>our</strong>,” or “<strong>us</strong>”). This Privacy Policy applies to information you provide to us when you (i) access or interact with us on our website (<a target="_blank" href="http://www.goldguysbullion.com">
    <span style="color:rgb(0, 0, 255);">www.goldguysbullion.com</span>
  </a>) and/or our online platform (collectively the “<strong>Platform</strong>”), (ii) create a user account on the Platform, (iii) access our Content (defined below), (iv) purchase our Products (defined below), or (v) interact with any of our marketing initiatives. This Privacy Policy specifies:&nbsp;</p>
<ul>
  <li>What personal information do we collect about you through our Platform?</li>
  <li>How is the collected information used and with whom may it be shared?</li>
  <li>What choices are available to you regarding the use of your data?</li>
  <li>What are our security practices to protect your information?</li>
  <li>How can you correct any inaccuracies in the collected information?</li>
</ul>
<p>
  <strong>By accessing and using the Platform, you agree to the collection and use of your information as described in this Privacy Policy.&nbsp; We have the right to revise this Privacy Policy at any time.&nbsp; Way may not notify users individually if we change this Privacy Policy.&nbsp; Any changes will be effective when posted on the Platform.&nbsp; We encourage you to check this Privacy Policy regularly for changes.&nbsp;</strong>
</p>
<p>
  <strong>Information We Provide and Collect</strong>
</p>
<p>We are the owners of the information, content, and other materials that we provide through the Platform (collectively the “<strong>Content</strong>”). This Privacy Policy specifies our rights to the information provided by you and/or collected through our Platform. Your information includes the information you provide in setting up your user account, when you purchase any items, services, or materials offered on our Platform (collectively our “<strong>Products</strong>”). We have access to and/or collect information that you voluntarily provide us via our Platform, any email you send us, any feedback you provide to us, your user account, or other direct contact from you.&nbsp; We may also collect information as a result of your interactions with our Platform or our marketing materials.&nbsp; We do not require you to set up a user account to access our Platform; however, an account is necessary to access portions of our Platform, access certain Content, or acquire our Products.&nbsp;</p>
<p>For purposes of this Privacy Policy, “<strong>Personal Information</strong>” includes all personally identifiable information that is specific to you (e.g. name, address, phone number, email address, etc.). This information may be combined with information already in our possession or made available to us through other information we are licensed to receive, and/or information we obtain from other sources. Except as specifically noted herein, we do not provide any Personal Information to any unaffiliated third party.&nbsp;</p>
<p>We may collect information about you through your use of our Platform with software application tools and data files (such as cookies and web log files), IP addresses, device state information, unique device identifiers, device hardware and OS information (“<strong>Usage Information</strong>”). You may be able to control such Usage Information through the control settings on your device or browser; but removing such Usage Information tools may impact the convenience to use or the functionality of the Platform.&nbsp;</p>
<p>We may collect and process information through our Platform about the location of your device using GPS or other location technologies, such as sensor data from your mobile device providing information about nearby Wi-Fi access points and cellular network towers (“<strong>Location Information</strong>”). We use Location Information as necessary to analyze and improve our Platform, Content, and Products.</p>
<p>
  <strong>Use and Disclosure of Your Information</strong>
</p>
<p>We use your Personal Information as necessary to operate the Platform, to maintain your user account, for the purpose of performing our services, and to provide our Products and Content. In addition, we may use Personal Information, Usage Information, or Location Information we collect or you provide to us, in order to:</p>
<ul>
  <li>Respond to any questions you ask or to respond to the reason you contacted us. We do not share your Personal Information with any unaffiliated third party, unless necessary to respond to your request.</li>
  <li>Enhance, improve and develop new content, services, and products and for overall customer support.</li>
  <li>To process your order and/or deliver the Products you purchase through our Platform.</li>
  <li>Monitor usage and interaction statistics on our Platform, and/or through responses to our communications.&nbsp;</li>
  <li>Perform data analytics for our internal business purposes.</li>
  <li>Allow us to respond quickly and efficiently to your questions and your requests for information.</li>
  <li>Communicate with you (via email, text, phone, or mail) either directly or indirectly through our marketing service provider to send you special offers, marketing information, or newsletters.</li>
  <li>Communicate with you to request feedback or to notify you of changes to our terms, conditions and Privacy Policy (though we are not required to provide any direct notification of such changes).</li>
  <li>Track use of our Platform, investigate suspicious activity, and enforce our terms and policies, to measure and improve the operation and security of our Platform and your Personal Information.</li>
  <li>Assess and improve our Platform, Products, and Content.</li>
  <li>Allow us to disclose your information to the extent permitted or required by law.</li>
</ul>
<p>We use the Usage Information to perform data analytics, analyze and evaluate the features and functionality of our Platform, Content, and Products. We may also use Usage Information to process automatic crash reporting which collects reports of crashes, other technical issues, and information relating to how our Platform is functioning.</p>
<p>We may use Location Information to customize the Platform, Products, Content or information specific to your region. You have the ability to control access to Location Information through the control settings on your device.</p>
<p>This information may also be provided to our Third Party Providers (as defined below) as necessary to provide our Platform, Content, and Products, and related functionality and offer other services and products. Our “<strong>Third Party Providers</strong>” may include, in addition to any of our data sources, software development, application and data hosting, wireless network services providers, payment processors, and any digital analytics or marketing services. We are responsible for assuring that these Third Party Providers comply with the terms of this Privacy Policy.</p>
<p>Except for our Third Party Providers, we will not share Personal Information, Usage Information, or Location Information with an unaffiliated third party without your prior authorization, unless doing so is necessary (1) to enforce this Privacy Policy, to comply with law, regulation or other legal processes or to protect the rights, property or safety of us or others, (2) to comply with a valid order or process from a public authority, (3) to protect against misuse or unauthorized use of our Platform or your user account, (4) to detect or prevent criminal activity or fraud, or (5) in the event that we or substantially all of our assets are acquired by one or more third parties as a result of an acquisition, merger, sale, reorganization, consolidation or liquidation, in which case such information may be one of the transferred assets.</p>
<p>
  <strong>Your Rights</strong>
</p>
<p>Depending on where you live, you may have certain legal rights under applicable law. For example, you may have some or all of the rights listed below (the following list may not include all rights your have depending on where you live).&nbsp; Our Platform is designed to serve individuals within the United States.&nbsp; The Platform does not target individuals outside of the United States, and we do not provide for our Products to be sold to individuals outside of the United States.&nbsp;&nbsp;</p>
<ul>
  <li>
    <strong>Right to Access</strong>
    – means you can ask us for a copy of any Personal Information we have about you, if any.</li>
  <li>
    <strong>Right to Correct</strong>
    – means you may ask us to change and/or correct any Personal Information we have about you if you believe any such Personal Information is incorrect or out of date.&nbsp;</li>
  <li>
    <strong>Right to Delete</strong>
    – means you may ask us to delete any Personal Information we have about you and we will be happy to do so unless we are required to retain such information by law or regulation or we have a right to retain such information under applicable law or subject to a user agreement for our internal business purposes.</li>
  <li>
    <strong>Right to Transfer</strong>
    – means you may request a copy of your Personal Information, in a commonly used and machine-readable format, be provided to you or to a third party you specify.</li>
  <li>
    <strong>Right to Limit</strong>
    – means you have the right at any time to unsubscribe to any marketing communication from us and we will promptly honor such request.&nbsp;</li>
</ul>
<p>We may update our Privacy Policy from time to time. We encourage you to review our Privacy Policy frequently to stay informed regarding how we collect, use, share and process Personal Information. You may contact us (see the Contact Us section below) at any time about any of these rights or any concern or question you have about our use of your Personal Information. See also the specific laws addendum.</p>
<p>
  <strong>Marketing</strong>
</p>
<p>We do not sell, lease, or otherwise disclose your Personal Information to unaffiliated third parties to market their products and services to you.&nbsp; While we may provide Personal Information or Usage Information to our Third Party Providers in order to communicate with you, we require all such Third Party Providers (as well as our affiliates and business partners) to comply with this Privacy Policy and to take appropriate steps to secure your information.</p>
<p>
  <strong>Security</strong>
</p>
<p>We take precautions to protect your Personal Information, including reasonable physical, administrative, and technical safeguards. When you submit Personal Information via our Platform, your Personal Information is transmitted using secure sockets layer (SSL) encryption technology. We restrict access to your Personal Information to our authorized personnel and/or Third Party Providers as appropriate and necessary to provide our Platform, Content, Products and/or maintain your user account.</p>
<p>We keep your Personal Information if it is necessary to process your requests, operate our business, and provide our Platform, Content, and Products, or as long as we are legally required to do so.&nbsp; For as long as we have your Personal Information, we will continue to protect the privacy and security of such Personal Information consistent with this Privacy Policy. However, no website, database or system is completely secure or “hacker proof.” You are also responsible for taking reasonable steps to protect your Personal Information against unauthorized disclosure or misuse. You are also responsible for protecting the security of your user account credentials and for any use of your user account.</p>
<p>
  <strong>Third Party Websites and Links</strong>
</p>
<p>Our Platform may contain hyperlinks to third party websites, applications, and information.&nbsp; Any such hyperlinks are provided only for your convenience, and we are not responsible for the privacy practices or content of external websites, applications, or information.&nbsp; Any access to and use of such third party websites, applications, or information is not governed by this Privacy Policy, but instead is governed by the privacy policies of those third party websites, applications, or information.&nbsp; We encourage you to review the privacy policies and other terms of any external website or application.</p>
<p>
  <strong>Payments</strong>
</p>
<p>We may permit payments to be made on the Platform for our Products or other services through use of a third party payment processor.&nbsp; We will not store or collect your payment card details.&nbsp; Payment information is provided directly to the payment processor, whose policies will apply to the processing of&nbsp; your payment.&nbsp;</p>
<p>
  <strong>Cookies</strong>
</p>
<p>A cookie is a small file which is placed on the user’s hard drive during a website visit.&nbsp; This file allows the user to move quickly and easily around our website. &nbsp; We use cookies, tacking pixels, and similar technologies to identify returning users and to track users’ sessions. We use this information to determine the usage of various Platform features and to analyze and develop the Platform.&nbsp; Also, in the course of serving advertisements to the Platform, third party advertisers may place or recognize a unique “cookie” on your browser.&nbsp;</p>
<p>Cookies can be “persistent” or “session” cookies. Persistent cookies remain on your personal computer or other device when you are offline, while session cookies are deleted as soon as you close your browser.&nbsp; You can delete cookies from your hard drive at any time.&nbsp;</p>
<p>
  <strong>Children’s Online Privacy Protection Act</strong>
</p>
<p>In compliance with the Children’s Online Privacy Protection Act, 15 U.S.C. § 6501.06 and 16 C.F.R. §§ 312.1 – 312.12, we do not knowingly collect information from children under the age of 13, nor does our Platform or our Content or Products target children under the age of 13. By using our Platform, Content, Products, and/or creating any user account, you represent that you are not younger than 13. Please contact us as noted below if you know or suspect that we have collected information from children under the age of 13 and we will take prompt measures to remove such information.</p>
<p>
  <strong>Accessibility</strong>
</p>
<p>We are committed to ensuring the accessibility of our Platform for people with disabilities. Our Platform is designed to be compatible with modern browsers and screen readers. In particular, for accessibility purposes, our websites rely on accepted industry standards (e.g., HTML, WAI-ARIA, CSS, and JavaScript) to work in combination with your web browser, assistive technologies, and plugins installed on your computer or device.&nbsp; Please contact us using the information provided in the “Contact Us” section below if you wish to receive any Content provided on our Platform in an alternative format to accommodate your disability, and we will use commercially reasonably efforts to provide the requested Content to you in an alternative format.&nbsp;</p>
<p>
  <strong>Contact Us</strong>
</p>
<p>If you have any questions, comments, or concerns about this Privacy Policy, please contact us via email at
  <a target="_blank" href="mailto:support@goldguys.com">
    <span style="color:rgb(0, 0, 255);">support@goldguys.com</span>
  </a>
  or via phone at 651-846-9710.&nbsp; All emails should include your first name, last name, email address, and the nature of your request.</p>
<p>Copyright © 2024 by Gold Guys Bullion, LLC. All rights reserved.</p>
<p style="text-align:center;">
  <span style="text-decoration:underline;">
    <strong>Addendum</strong>
  </span>
</p>
<p>
  <span style="text-decoration:underline;">
    <strong>United States</strong>
  </span>
</p>
<p>The following additional terms may apply to you depending on where you reside in the United States. To the extent of any inconsistency, these terms take precedence over the terms in our Privacy Policy in relation to personal information that is collected and/or held in the United States.</p>
<p>Please use the contact information in the Privacy Policy if you wish to access or correct any of your personal information that we hold or if you would like to report a potential breach by us of any applicable laws of the United States, our Privacy Policy, or this Addendum.&nbsp; We will promptly acknowledge and investigate any such reports.</p>
<p>The laws of the states described below are the ones we are currently aware of that require giving individual notice and/or consent with respect to our Platform, Content, or Products, and that provide for specific individual rights with respect to our Platform, Content, or Products. We recognize that other states also have laws that may affect your privacy rights with respect to our Platform, Content, or Products, and we direct you to the information in our Privacy Policy for a description of such rights.</p>
<p>As described further in our Privacy Policy, in the preceding twelve months, we or our service providers may have collected the below categories of personal information for business or commercial purposes:</p>
<ul>
  <li>Identifiers (such name, email address, address, and phone number);</li>
  <li>Commercial information (such as transaction data);</li>
  <li>Internet or other network or device activity (such as IP address, unique device, advertising ID, browsing history or other usage data);</li>
  <li>Location information (general location, and, if you provide permission, precise GPS location);</li>
  <li>Sensory information (such as audio recordings if you call our customer service);</li>
  <li>Inferences about your preferences and traits through your use of the Platform; and</li>
  <li>Other information that identifies or can be reasonably associated with you.</li>
</ul>
<p>We collect the categories of personal information identified above from the following sources: (1) directly from you; (2) through your use of the Platform; (3) affiliates; and (4) third parties such as other users or our third party providers.</p>
<p>We or our service providers may collect the categories of information identified above for the following business or commercial purposes (as those terms are defined in applicable law):</p>
<ul>
  <li>Our or our service provider’s operational purposes;</li>
  <li>Auditing consumer interactions on our site (e.g., measuring ad impressions);</li>
  <li>Detecting, protecting against, and prosecuting security incidents, fraudulent or illegal activity and activity that violates any terms or policies;</li>
  <li>Bug detection, error reporting, and activities to maintain the quality or safety of our Platform, Content, or Products;</li>
  <li>Short-term, transient use, such as customizing content that we or our service providers display on services;</li>
  <li>Providing services (e.g., account servicing and maintenance, data processing, customer service, advertising and marketing, analytics, communication about our Platform, Content, or Products, facilitating communications between users);</li>
  <li>Improving our existing Products and developing new Products (e.g., by conducting research to develop new products or features, or to train our employees on issues that our users need to be resolved);</li>
  <li>Improving the Platform and Content;&nbsp;</li>
  <li>Other uses that advance our commercial or economic interests, such as third party advertising and communicating with you about relevant offers from third party partners; and&nbsp;</li>
  <li>Other uses about which we notify you.</li>
</ul>
<p>We describe our information sharing practices in the Privacy Policy. In the previous twelve months, we may have shared certain categories of personal information with third parties for business purposes. The personal information shared may include the following categories of personal information: (1) identifiers; (2) commercial information; (3) location information; and (4) other information that can be associated with you.</p>
<p>Examples of these types of uses are identified below. We may also use the below categories of personal information for compliance with applicable laws and regulations, and we may combine the information we collect (“<strong>aggregate</strong>”) or remove pieces of information (“<strong>de-identify</strong>”) to limit or prevent identification of any particular user or device.</p>
<p>
  <strong>Table of Categories of Data, Uses and With Whom Shared</strong>
</p>
<div style="overflow:scroll">
  <table class="privacyTable">
    <tbody>
      <tr>
        <td>
          <span style="text-decoration:underline;">
            <strong>Categories of Personal Information We Collect</strong>
          </span>
        </td>
        <td>
          <span style="text-decoration:underline;">
            <strong>Examples of Uses</strong>
          </span>
        </td>
        <td>
          <span style="text-decoration:underline;">
            <strong>Categories of Third Parties With Which We May Share That Information</strong>
          </span>
        </td>
        <td>
          <span style="text-decoration:underline;">
            <strong>Collected/Shared</strong>
          </span>
        </td>
      </tr>
      <tr>
        <td>Identifiers (e.g., real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name)</td>
        <td>Providing our Platform, Content, and Products, Updating and improving our Platform, Content, and Products, Personalizing content, Marketing and advertising, Communicating with you, Analyzing your use of our Platform, Preventing, detecting, investigating, and responding to fraud, unauthorized access/use of our Platform, breaches or potential breaches of terms and policies, Internal training of our personnel</td>
        <td>Third Party Providers</td>
        <td>Yes</td>
      </tr>
      <tr>
        <td>Any personal information described in subdivision (e) of Section 1798.80 (e.g., name, address, telephone number, bank<span style="text-decoration:underline;">
            <strong></strong>
          </span>account number, credit card number, debit card number, or any other financial information (with financial information only as required by our Third Party Provider (i.e., Stripe))</td>
        <td>Providing our Platform, Content, and Products, Updating and improving our Platform, Content, and Products, Communicating with you, Analyzing your use of our Platform, Preventing, detecting, investigating, and responding to fraud, unauthorized access/use of our services, breaches or potential breaches of terms and policies, Internal training of our personnel</td>
        <td>Third Party Providers</td>
        <td>Yes, though bank account information is collected directly by our Third Party Provider responsible for payment processing and not by us.&nbsp;&nbsp;</td>
      </tr>
      <tr>
        <td>Internet or other electronic network activity information (e.g., browsing history, search history, and information regarding a consumer’s interaction with an internet website application, or advertisement)</td>
        <td>Providing our Platform, Content, and Products·, Updating and improving our Platform, Content, and Products, Personalizing content, Marketing and advertising, Analyzing use of our Platform, Preventing, detecting, investigating, and responding to fraud, unauthorized access/use of our services, or breaches or potential breaches of terms and policies</td>
        <td>Third Party Providers</td>
        <td>Yes</td>
      </tr>
      <tr>
        <td>Geolocation information (general location, and, if you provide permission, precise GPS location)</td>
        <td>Providing our Platform, Content, and Products, Updating and improving our Platform, Content, and Products, Personalizing content, Marketing and advertising, Analyzing use of our Platform, Preventing, detecting, investigating, and responding to fraud, unauthorized access/use of our services, or breaches or potential breaches of terms and policies</td>
        <td>Third Party Providers</td>
        <td>Yes</td>
      </tr>
      <tr>
        <td>Sensory information (e.g., audio, electronic, visual, thermal, olfactory, or similar information)</td>
        <td>Providing our Platform, Content, and Products, Updating and improving our Platform, Content, and Products, Personalizing content, Analyzing use of our Platform, Preventing, detecting, investigating, and responding to fraud, unauthorized access/use of our services, or breaches or potential breaches of terms and policies, Internal training of our personnel</td>
        <td>Third Party Providers</td>
        <td>Yes</td>
      </tr>
      <tr>
        <td>Professional or employment-related information</td>
        <td>Providing our Platform, Content, and Products, Updating and improving our Platform, Content, and Products, Analyzing use of our Platform, Preventing, detecting, investigating, and responding to fraud, unauthorized access/use of our services, or breaches or potential breaches of terms and policies, Internal training of our personnel</td>
        <td>Third Party Providers</td>
        <td>Yes</td>
      </tr>
      <tr>
        <td>Inferences drawn (to create a profile about a consumer reflecting the consumer’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes)</td>
        <td>Providing our Platform, Content, and Products, Updating and improving our Platform, Content, and Products, Personalizing content, Analyzing use of our Platform, Preventing, detecting, investigating, and responding to fraud, unauthorized access/use of our services, or breaches or potential breaches of terms and policies</td>
        <td>Third Party Providers</td>
        <td>Yes</td>
      </tr>
      <tr>
        <td>Sensitive personal information (e.g., account log-in, financial account, debit card, or credit card number with any required security or access code, password, or credentials allowing access to an account; precise geolocation)</td>
        <td>Financial information is required by our Third Party Provider for payment processing (i.e., Stripe). The only information provided to us from Stripe is last four digits of card, email, and zip code. Our Third Party Provider uses to: Process payments for our Products, Updating and improving our Platform, Content, and Products, Analyzing use of our Platform, Preventing, detecting, investigating, and responding to fraud, unauthorized access/use of our services, or breaches or potential breaches of terms and policies</td>
        <td>Third Party Providers</td>
        <td>Yes, though payment card information is processed directly and stored by our Third Party Provider payment processor.&nbsp;</td>
      </tr>
    </tbody>
  </table>
</div>
<p>We collect the categories of personal information identified above from the following sources: (1) directly from you; (2) through your use of the Platform; (3) affiliates; and (4) third parties such as other users or our third party providers.</p>
<p>
  <strong>For Residents of California</strong>
</p>
<p>If you are a California resident (as defined by the California Consumer Privacy Act, as amended), you may have certain rights.</p>
<p>This Notice for California Residents supplements the information in our Privacy Policy, and except as provided herein, applies solely to California residents (as defined by the California Consumer Privacy Act (CCPA) and amended by the Consumer Privacy Act Regulations (CPRA)). It may apply to personal information (as defined by CCPA / CPRA) we collect on or through the Platform and through other means (such as information collected offline, in person, and over the telephone). Until the CCPA / CPRA has specifically regulated business information, this Notice for California Residents does not apply to business information that does not constitute personal information.</p>
<p>
  <span style="text-decoration:underline;">Summary of Information We Collect</span>
</p>
<p>California law requires us to disclose information regarding the categories of personal information that we have collected about California consumers, the categories of sources from which we collect personal information, the business or commercial purposes (as each of those terms are defined by applicable law) for which we collect personal information, and the categories of parties with whom we share personal information. See the details as noted above for categories of information and uses.</p>
<p>
  <span style="text-decoration:underline;">Rights</span>
</p>
<p>If you are a California resident (as defined by the CCPA / CPRA), you may have certain rights. California law may permit you to request that we:</p>
<ul>
  <li>Provide you the categories of personal information we have collected or disclosed about you in the last twelve months; the categories of sources of such information; the business or commercial purpose for collecting or selling your personal information, if applicable (note, at this time we do not sell or share personal information with unaffiliated third parties); and the categories of third parties with whom we shared personal information.</li>
  <li>Provide access to and/or a copy of certain information we hold about you.</li>
  <li>Request to opt-out of the sale or sharing of personal information.&nbsp;</li>
  <li>Delete certain information we have about you.</li>
</ul>
<p>You may have the right to receive information about the financial incentives that we offer to you, if any. You also have the right to not be discriminated against (as provided for in applicable law) for exercising certain of your rights referenced herein. Certain information may be exempt from such requests under applicable law. In addition, we need certain types of information so that we can provide our Platform, Content, and Products to you. If you ask us to delete it, you may no longer be able to access or use our Platform, Content, or purchase our Products.</p>
<p>If you would like to exercise any of these rights, please submit a request to&nbsp;<a target="_blank" href="mailto:support@goldguys.com">
    <span style="color:rgb(0, 0, 255);">support@goldguys.com</span>
  </a>. You will be required to verify your identity before we are able to fulfil your request. You can also designate an authorized agent to make a request on your behalf. To do so, you must provide us with written authorization or a power of attorney, signed by you, for the agent to act on your behalf. You will still need to verify your identity directly with us.</p>
<p>The CCPA / CPRA, sets forth certain obligations for businesses that “sell” personal information. We do not sell personal information.</p>
<p>
  <span style="text-decoration:underline;">Metrics</span>
</p>
<p>California law may require us to compile the following metrics for the previous calendar year: the number of rights requests received, complied with, and denied, as well as the median number of days within which we responded to those requests. To the extent this obligation applies to us, we will update this section.&nbsp;</p>
<p>
  <span style="text-decoration:underline;">California Shine the Light</span>
</p>
<p>If you are a California resident, you may ask for a list of third parties that have received your information for direct marketing purposes during the previous calendar year. If we have shared your information, this list will contain the types of information shared, and we will provide this list at no cost. To make such a request, contact us at
  <a target="_blank" href="mailto:support@goldguys.com">
    <span style="color:rgb(0, 0, 255);">support@goldguys.com</span>
  </a>.</p>
<p>
  <span style="text-decoration:underline;">California Do-Not-Track Disclosure</span>
</p>
<p>We are committed to providing you with meaningful choices about the information collected on our Platform for third party purposes. However, we do not currently recognize or respond to browser-initiated Do-Not-Track signals, as the Internet industry is currently still working on Do-Not-Track standards, implementations, and solutions.</p>
<p>
  <strong>For Residents of Colorado</strong>
</p>
<p>If you are a resident of Colorado and you meet the definition of a “consumer,” you may have certain rights.</p>
<p>
  <span style="text-decoration:underline;">Summary of Information We Collect</span>
</p>
<p>Colorado law requires us to disclose information regarding the categories of personal data that we have collected about Colorado consumers, the categories of sources from which we collect personal information, the business or commercial purposes (as each of those terms are defined by applicable law) for which we collect personal information, and the categories of parties with whom we share personal information. See the details as noted above for categories of information and uses.</p>
<p>
  <span style="text-decoration:underline;">Rights</span>
</p>
<p>Colorado law may permit you to request that we act on a consumer’s following rights:</p>
<ul>
  <li>Right of access to and/or a copy of certain information we hold about you.</li>
  <li>Right to correct for any inaccuracies in your personal data.</li>
  <li>Right to request that we delete your personal data.</li>
  <li>Right to obtain your personal data in a portable (and if technically feasible readily usable) format.</li>
  <li>Right to request to opt-out of the sale of personal data, targeted advertising, or profiling in furtherance of decisions that produce legal or similarly significant effects. We do not currently sell personal data or engage in profiling.&nbsp;</li>
</ul>
<p>If you would like to exercise any of these rights, please submit a request to
  <a target="_blank" href="mailto:support@goldguys.com">
    <span style="color:rgb(0, 0, 255);">support@goldguys.com</span>
  </a>. You will be required to verify your identity before we are able to fulfill your request. You can also designate an authorized agent to make a request on your behalf. To do so, you must provide us with written authorization or a power of attorney, signed by you, for the agent to act on your behalf. You will still need to verify your identity directly with us.</p>
<p>Please note that while we may record customer service calls and/or training videos; we do not digitally analyze any such data for any biometric identification purposes.</p>
<p>To opt out of the sale/sharing of your personal information, you may click
  <a target="_blank" href="http://www.goldguysbullion.com/opt-out">
    <span style="color:rgb(0, 0, 255);">www.goldguysbullion.com/opt-out</span>
  </a>
  <span style="color:rgb(255, 0, 0);">.</span>
</p>
<p>
  <strong>For Residents of Connecticut</strong>
</p>
<p>If you are a resident of Connecticut and you meet the definition of a “consumer,” you may have certain rights.</p>
<p>
  <span style="text-decoration:underline;">Summary of Information We Collect</span>
</p>
<p>Connecticut law requires us to disclose information regarding the categories of personal data that we have collected about Connecticut consumers, the categories of sources from which we collect personal information, the business or commercial purposes (as each of those terms are defined by applicable law) for which we collect personal information, and the categories of parties with whom we share personal information. See the details as noted above for categories of information and uses.</p>
<p>
  <span style="text-decoration:underline;">Rights</span>
</p>
<p>Connecticut law may permit you to request that we act on a consumer’s following rights:</p>
<ul>
  <li>Right of access to and/or a copy of certain information we hold about you.</li>
  <li>Right to correct for any inaccuracies in your personal data.</li>
  <li>Right to request that we delete your personal data.</li>
  <li>Right to obtain your personal data in a portable (and if technically feasible readily usable) format.</li>
  <li>Right to request to opt-out of the sale of personal data, targeted advertising, or profiling in furtherance of decisions that produce legal or similarly significant effects. We do not currently sell personal data or engage in profiling.&nbsp;</li>
</ul>
<p>If you would like to exercise any of these rights, please submit a request to
  <a target="_blank" href="mailto:support@goldguys.com">
    <span style="color:rgb(0, 0, 255);">support@goldguys.com</span>
  </a>. You will be required to verify your identity before we are able to fulfil your request. You can also designate an authorized agent to make a request on your behalf. To do so, you must provide us with written authorization or a power of attorney, signed by you, for the agent to act on your behalf. You will still need to verify your identity directly with us.</p>
<p>Please note that while we may record customer service calls and/or training videos; we do not digitally analyze any such data for any biometric identification purposes.</p>
<p>To opt out of the sale/sharing of your personal information, you may click
  <a target="_blank" href="http://www.goldguysbullion.com/opt-out">
    <span style="color:rgb(0, 0, 255);">www.goldguysbullion.com/opt-out</span>
  </a>
  <span style="color:rgb(255, 0, 0);">.</span>
</p>
<p>
  <strong>For Residents of Illinois</strong>
</p>
<p>
  <span style="text-decoration:underline;">Rights</span>
</p>
<p>Residents of Illinois may have certain rights under the Biometric Information Privacy Act. Please note that while we may record customer service calls and/or training videos; we do not digitally analyze any such data for any biometric identification purposes.</p>
<p>
  <strong>For Residents of Nevada</strong>
</p>
<p>
  <span style="text-decoration:underline;">Rights</span>
</p>
<p>Under Nevada law, certain Nevada consumers may opt out of the sale of “personally identifiable information” for monetary consideration to a person for that person to license or sell such information to additional persons. “Personally identifiable information” includes first and last name, address, email address, phone number, Social Security Number, or an identifier that allows a person to be contacted either physically or online.</p>
<p>We do not engage in such activity.</p>
<p>
  <strong>For Residents of Virginia</strong>
</p>
<p>If you are a resident of Virginia and you meet the definition of a “consumer,” you may have certain rights.</p>
<p>
  <span style="text-decoration:underline;">Summary of Information We Collect</span>
</p>
<p>Virginia law requires us to disclose information regarding the categories of personal data that we have collected about Virginia consumers, the categories of sources from which we collect personal information, the business or commercial purposes (as each of those terms are defined by applicable law) for which we collect personal information, and the categories of parties with whom we share personal information. See the details as noted above for categories of information and uses.</p>
<p>
  <span style="text-decoration:underline;">Rights</span>
</p>
<p>Virginia law may permit you to request that we act on a consumer’s following rights:</p>
<ul>
  <li>Right to confirm if we are processing personal data we hold about you.</li>
  <li>Right to correct for any inaccuracies in your personal data.</li>
  <li>Right to request that we delete your personal data.</li>
  <li>Right to obtain your personal data in a portable (and if technically feasible readily usable) format.</li>
  <li>Right to request to opt-out of the sale of personal data, targeted advertising, or profiling in furtherance of decisions that produce legal or similarly significant effects. We do not currently sale personal data or engage in profiling.&nbsp;</li>
</ul>
<p>If you would like to exercise any of these rights, please submit a request to
  <a target="_blank" href="mailto:support@goldguys.com">
    <span style="color:rgb(0, 0, 255);">support@goldguys.com</span>
  </a>. You will be required to verify your identity before we are able to fulfil your request. You can also designate an authorized agent to make a request on your behalf. To do so, you must provide us with written authorization or a power of attorney, signed by you, for the agent to act on your behalf. You will still need to verify your identity directly with us.</p>
<p>Please note that while we may record customer service calls and/or training videos; we do not digitally analyze any such data for any biometric identification purposes.</p>
<p>To opt out of the sale/sharing of your personal information, you may click
  <a target="_blank" href="http://www.goldguysbullion.com/opt-out">
    <span style="color:rgb(0, 0, 255);">www.goldguysbullion.com/opt-out</span>
  </a>
  <span style="color:rgb(255, 0, 0);">.</span>
</p>
<p>
  <strong>For Residents of Utah</strong>
</p>
<p>If you are a resident of Utah and you meet the definition of a “consumer,” you may have certain rights.</p>
<p>
  <span style="text-decoration:underline;">Summary of Information We Collect</span>
</p>
<p>Utah law requires us to disclose information regarding the categories of personal data that we have collected about Utah consumers, the categories of sources from which we collect personal information, the business or commercial purposes (as each of those terms are defined by applicable law) for which we collect personal information, and the categories of parties with whom we share personal information. See the details as noted above for categories of information and uses.</p>
<p>
  <span style="text-decoration:underline;">Rights</span>
</p>
<p>Utah law may permit you to request that we act on a consumer’s following rights:</p>
<ul>
  <li>Right to confirm if we are processing personal data we hold about you.</li>
  <li>Right to correct for any inaccuracies in your personal data.</li>
  <li>Right to request that we delete your personal data.</li>
  <li>Right to obtain your personal data in a portable (and if technically feasible readily usable) format.</li>
  <li>Right to request to opt-out of the sale of personal data, targeted advertising, or profiling in furtherance of decisions that produce legal or similarly significant effects. We do not currently engage in the sale of personal data or engage in profiling.&nbsp;</li>
</ul>
<p>If you would like to exercise any of these rights, please submit a request to
  <a target="_blank" href="mailto:support@goldguys.com">
    <span style="color:rgb(0, 0, 255);">support@goldguys.com</span>
  </a>.&nbsp; You will be required to verify your identity before we are able to fulfil your request. You can also designate an authorized agent to make a request on your behalf. To do so, you must provide us with written authorization or a power of attorney, signed by you, for the agent to act on your behalf. You will still need to verify your identity directly with us.</p>
<p>To opt out of the sale/sharing of your personal information, you may click
  <a target="_blank" href="http://www.goldguysbullion.com/opt-out">
    <span style="color:rgb(0, 0, 255);">www.goldguysbullion.com/opt-out</span>
  </a>
  <span style="color:rgb(255, 0, 0);">.</span>
</p>
`
React.useEffect(() => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant'
  })
}, [])
    return(
        <div className='defPageMarg'>
            <div className='privacyMarg'>
                <div dangerouslySetInnerHTML={{ __html: html }} />           
            </div>
        </div>
    )

}

import * as React from 'react';
import {Helmet} from "react-helmet";
import PLPCard from '../SmallComponents/PLPCard.tsx';
import { CardContent, CardMedia, CircularProgress, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
const algoliasearch = require("algoliasearch");
const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_APP_SEARCH_KEY);
export default function SearchDisplayComonent(props){
    const [indexV, setIndexV] = React.useState('most_popular');
    var index = client.initIndex(indexV);
    const location = useLocation()
    const[itemList,setItemList] = React.useState([])
    const [isChecked, setChecked] = React.useState(false);
    const [cat, setCat] = React.useState('');
    const [cat2, setCat2] = React.useState('');
    const [cat3, setCat3] = React.useState('');
    const [searchVal, setSearchVal] = React.useState('');
    const [headerBackgroundStyle, setHeaderBackground] = React.useState('');
    const [customHeaderBR, setcustomHeaderBR] = React.useState('');
    const [isLoadingSearch, setLoadingSearch] = React.useState(true);
    const [resultNumber, setResultNumber] = React.useState(0);
    const [breadSearchComplete, setBreadSearchComplete] = React.useState(false);
    const [needRefresh,setNeedRefresh] = React.useState(false)
    const[seoData,setSeoData] = React.useState({})

    const [pathParams, setPathParams] = React.useState([]);
    const nav = useNavigate()

    const components: JSX.Element[] = []; // Specify the type as JSX.Element[]
    const numComponentsToRender = 10;
    var imageString = ""
    var itemName = ""
    var imageCat = props.categoryDisplay;
    
    const [sort, setSort] = React.useState('');
    const [showSort, setShowSort] = React.useState(false);
    function getUrlParamValue(paramName) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const paramValue = urlSearchParams.get(paramName);
        return paramValue;
      }

    function fetchPath(url){
      if(url.toLowerCase().includes("silver")){
        return ["Silver"]
      }
      if(url.toLowerCase().includes("gold")){
        return ["Gold"]
      }
      if(url.toLowerCase().includes("platinum")){
        return ["Platinum"]
      }
      if(url.toLowerCase().includes("deals")){
        return ["Deals"]
      }
      return []
    }


    React.useEffect(() => {
      // Set a timeout to call `myFunction` after 5 seconds
      const timer = setTimeout(() => {
        setNeedRefresh(true)
      }, 43200000); // 12

      // Cleanup function to clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }, []); // Empty dependency array means it runs only once
      
    React.useEffect(()=>{

        const currentURL = window.location.href;
        const url = new URL(currentURL);
        const pathname = url.pathname.replaceAll("-", " ")
        // Use the URL constructor to parse the URL and get the pathname
        //const url = new URL(currentURL);
        if(location?.state?.breadCrumb){
            const pathname = location?.state.breadCrumb?.replaceAll("-", " ")
      
            // Split the pathname into segments using '/' as the delimiter
            const segments = pathname.split('/');
          
            // Filter out empty segments and return the path parameters
            const pathParameters = segments
            .filter(segment => segment !== '')
            .map(decodeURIComponent);
            setPathParams(pathParameters)
        }else{
          const pathname = location.pathname
      
          // Split the pathname into segments using '/' as the delimiter
          const segments = pathname.split('/');
        
          // Filter out empty segments and return the path parameters
          const pathParameters = segments
          .filter(segment => segment !== '')
          .map(decodeURIComponent);
          setPathParams(pathParameters)
        }

        setcustomHeaderBR("")
        if(String(url).toLowerCase().includes("silver")){
          setHeaderBackground("fgrey")
        } else 
        if(String(url).toLowerCase().includes("gold")){
          setHeaderBackground("goldBack")
        } else
        if(String(url).toLowerCase().includes("platinum")){
          setHeaderBackground("platBack")
        } else
        if(String(url).toLowerCase().includes("deals")){
          setcustomHeaderBR("redback")
          setHeaderBackground("")
        }else{
          setcustomHeaderBR("")
          setHeaderBackground("")
        }
        
        
        //Get searchterm
        const searchParamValue = getUrlParamValue('search');
        if(searchParamValue != undefined || searchParamValue != "" && searchParamValue != null){
            setSearchVal(searchParamValue)
            setPathParams([])
        }else{
          setSearchVal("")
        }
        setBreadSearchComplete(true)

    },[nav,location])

    const handleChange = (event) => {
      setIndexV(event.target.value);
    };
    const handleCheckboxChange = () => {
        setChecked(!isChecked);
      };
      React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [location])

      function customComparisonMostPopular(a, b) {
        // Example: Sort by a custom attribute 'customAttribute'
        if (parseFloat(a.stock) > parseFloat(b.stock)) {
          return -1;
        }
        if (parseFloat(a.stock) < parseFloat(b.stock)) {
          return 1;
        }
        return 0;
      }

      function customCparison_item_asc(a, b) {
        // Example: Sort by a custom attribute 'customAttribute'
        let priceA = a?.price ?? 0 
        let priceBookABook = a?.priceBookRate
        let priceBookA = 0 
        if(priceBookABook.length > 0){
          priceBookA = priceBookABook[0].pricebook_rate
        }else{
          priceBookA = priceBookABook
        }
        let atotal = priceA + priceBookA
        let silverCont = a.customField.cf_silver_content
        atotal = parseFloat(atotal) * parseFloat(silverCont)

        let priceB = b?.price ?? 0 
        let priceBookBBook = b?.priceBookRate ?? 0 
        let priceBookB = 0 
        if(priceBookBBook.length > 0){
          priceBookB = priceBookBBook[0].pricebook_rate
        }else{
          priceBookB = priceBookBBook
        }
        let btotal = priceB + priceBookB
        let silverContB = b.customField.cf_silver_content
        btotal = parseFloat(btotal) * parseFloat(silverContB)

        if (parseFloat(atotal) < parseFloat(btotal)) {
          return -1;
        }
        if (parseFloat(atotal) > parseFloat(btotal)) {
          return 1;
        }
        return 0;
      }

      function customCparison_item_desc(a, b) {
        // Example: Sort by a custom attribute 'customAttribute'
        let priceA = a?.price ?? 0 
        let priceBookABook = a?.priceBookRate
        let priceBookA = 0 
        if(priceBookABook.length > 0){
          priceBookA = priceBookABook[0].pricebook_rate
        }else{
          priceBookA = priceBookABook
        }
        let atotal = priceA + priceBookA
        let silverCont = a.customField.cf_silver_content
        atotal = parseFloat(atotal) * parseFloat(silverCont)

        let priceB = b?.price ?? 0 
        let priceBookBBook = b?.priceBookRate ?? 0 
        let priceBookB = 0 
        if(priceBookBBook.length > 0){
          priceBookB = priceBookBBook[0].pricebook_rate
        }else{
          priceBookB = priceBookBBook
        }
        let btotal = priceB + priceBookB
        let silverContB = b.customField.cf_silver_content
        btotal = parseFloat(btotal) * parseFloat(silverContB)

        if (parseFloat(atotal) > parseFloat(btotal)) {
          return -1;
        }
        if (parseFloat(atotal) < parseFloat(btotal)) {
          return 1;
        }
        return 0;
      }

    React.useEffect(()=>{
        if(breadSearchComplete){
        var filter = ""
        var limit = 100
        if(props != undefined && props.limitRes == true){
          limit = 8
          setShowSort(false)
        }else{
          setShowSort(true)
        }


        if(pathParams.length == 0 && imageCat == ""){
          return(
            <div style={{textAlign:'center',margin:50,height:300}}>
              <CircularProgress/>
            </div>
          )
        }

        if(pathParams.length == 0 && location.pathname != "/" && imageCat != "Search"){
          return
        }

        if(pathParams.length > 0 && pathParams[0] != "search"){ 
            //if(pathParams.includes("silver"))
            for(let i = 0; i < pathParams.length; i++ ){
                if(i == 0 ){
                    filter = 'category:' + pathParams[i]
                }
                if(i == 1){
                    filter = filter + ' AND category2: "' + pathParams[i] +'"'
                }
                if(i == 2){
                    filter = filter + ' AND category3: "' + pathParams[i] +'"'
                }
                if(pathParams.includes("Deals")){
                    filter ='dealitem: true'
                }
            }
            if(imageCat == "US Silver Coins"){
              filter = filter + 'category3:"U.S. Silver Coins"'
              limit = 4
            }
            if(imageCat == "New Arrivals"){
              setShowSort(false)
              index = client.initIndex("date_desc");
              const exfilter = `NOT category:Collectibles AND NOT category:Supplies`;
              filter = exfilter
              limit = 30
            }
            if(imageCat == "supplies" || imageCat ==  "Collectibles" || pathParams[0] ==  "collectibles"|| pathParams[0] ==  "supplies"){
              setShowSort(false)
            }
              index
            .search(searchVal,{filters:filter,hitsPerPage:limit})
            .then(({ hits }) => {
              setResultNumber(hits.length)

                if(indexV == "most_popular"){
                  hits.sort(customComparisonMostPopular)
                }
                if(indexV == "item_price_asc"){
                  hits.sort(customCparison_item_asc)
                }
                if(indexV == "item_price_desc"){
                  hits.sort(customCparison_item_desc)
                }
                setItemList(hits)
                imageCat = ""
                setLoadingSearch(false)

            })
            .catch(err => {
                console.log(err);
            });
        }else{
            if(imageCat == "Deal Zone"){
                filter ='dealitem: true'
                limit = 12
            }
            if(imageCat == "New Arrivals"){
              index = client.initIndex("date_desc");
              const exfilter = `NOT category:Collectibles AND NOT category:Supplies`;

              filter = exfilter
              limit =4

            }
            if(imageCat == "Junk Silver"){
              filter = filter + 'category2:"Junk Silver"'
              limit = 4
            }
            if(imageCat == "US Silver Coins"){
              filter = filter + 'category3:"U.S. Silver Coins"'
              limit = 4
            }
            if(imageCat == "Silver Bars"){
              filter = filter + 'category2:"Silver Bars"'
              limit = 4
            }
            if(imageCat == "Fractional Gold Bars"){
              filter = filter + 'category3:"Fractional Gold Bars"'
              limit = 4
            }

            if(imageCat != ""){
            index
            .search(searchVal,{filters:filter,hitsPerPage:limit})
            .then(({ hits }) => {
                setResultNumber(hits.length)
                setItemList(hits)
                imageCat = ""
                setLoadingSearch(false)

            })
            .catch(err => {
                console.log(err);
            });
          }
        }
      }
    },[pathParams,indexV,location,searchVal,imageCat,breadSearchComplete,needRefresh])
    function capitalizeFirstLetter(str) {
        str = str.replaceAll("-" , " ")
        return str.charAt(0).toUpperCase() + str.slice(1);
      }

    let coincats = [{name:"U.S. Silver Coins",bread:'/Silver/Silver-Coins/U.S.-Silver-Coins',image:'/static/images/US Silver Coins.png'}, 
    {name:"Australian Silver Coins",bread:'/Silver/Silver-Coins/Australian-Silver-Coins',image:'/static/images/Australian Silver Coins (1).png'}, 
    {name:"Austrian Silver Coins",bread:'/Silver/Silver-Coins/Austrian-Silver-Coins',image:'/static/images/Austrain Silver Coins.png'}, 
    {name:"British Silver Coins",bread:'/Silver/Silver-Coins/British-Silver-Coins',image:'/static/images/British Silver Coins (1).png'},{name:"Canadian Silver Coins",bread:'/Silver/Silver-Coins/Canadian-Silver-Coins',image:'/static/images/Canadian Silver Coins.png'},{name:"Chinese Silver Coins",bread:'/Silver/Silver-Coins/Chinese-Silver-Coins',image:'/static/images/Chinese Silver Coins (1).png'},{name:"Mexican Silver Coins",bread:'/Silver/Silver-Coins/Mexican-Silver-Coins',image:'/static/images/1 2024-1-oz-Mexican-Silver-Libertad-Coin-440x440.png'},{name:"Niue Silver Coins",bread:'/Silver/Silver-Coins/Niue-Silver-Coins',image:'/static/images/Niue Silver Coins (1).png'},{name:"African Silver Coins",bread:'/Silver/Silver-Coins/African-Silver-Coins',image:'/static/images/African Silver Coins (1).png'},{name:"Fractional Silver Coins",bread:'/Silver/Silver-Coins/Fractional-Silver-Coins'},{name:"Other Silver Coins",bread:'/Silver/Silver-Coins/Other-Silver-Coins',image:'/static/images/Other Silver Coins (1).png'}]
    let silverSubCats = [{name:"Silver Coins",bread:'/Silver/Silver-Coins',image:'/static/images/Silver Coins (1).png'}, {name:"Silver Rounds",bread:'/Silver/Silver-Rounds',image:'/static/images/Silver Rounds (1).png'}, 
    {name:"Silver Bars",bread:'/Silver/Silver-Bars',image:'/static/images/Silver Bars (1).png'}, {name:"Fractional Silver",bread:'/Silver/Fractional-Silver',image:'/static/images/Fractional Silver (1).png'}, {name:"Junk Silver",bread:'/Silver/Junk-Silver',image:'/static/images/Junk Silver (1).png'}, 
    ]
    let goldSubCats = [{name:"Gold Coins",bread:'/Gold/Gold-Coins',image:'/static/images/Gold Coins (1).png'}, {name:"Gold Bars",bread:'/Gold/Gold-Bars',image:'/static/images/Gold Bars (1).png'}, 
    ]
    let silverBarSubcats = [{name:"1 oz Silver Bars",bread:'/Silver/Silver-Bars/1-oz-Silver-Bars',image:'/static/images/1 oz Silver Bars (1).png'},{name:"5 oz Silver Bars",bread:'/Silver/Silver-Bars/5-oz-Silver-Bars',image:'/static/images/5 oz Silver Bars (1).png'},{name:"10 oz Silver Bars",bread:'/Silver/Silver-Bars/10-oz-Silver-Bars',image:'/static/images/10 oz Silver Bars (1).png'},
    {name:"100 oz Silver Bars",bread:'/Silver/Silver-Bars/100-oz-Silver-Bars',image:'/static/images/100 oz Silver Bars (1).png'},
    {name:"Kilo Silver Bars",bread:'/Silver/Silver-Bars/Kilo-Silver-Bars',image:'/static/images/Kilo Silver Bars (1).png'},
    {name:"Other Silver Bars",bread:'/Silver/Silver-Bars/Other-Silver-Bars',image:'/static/images/Other Silver Bars (1).png'},
    ]
    let goldcoincats= [{name:"U.S. Gold Coins",bread:'/Gold/Gold-Coins/U.S.-Gold-Coins',image:'/static/images/US Gold Coins.png'}, 
    {name:"Australian Gold Coins",bread:'/Gold/Gold-Coins/Australian-Gold-Coins'}, 
    {name:"Austrian Gold Coins",bread:'/Gold/Gold-Coins/Austrian-Gold-Coins',image:'/static/images/Austrian Gold Coins (1).png'}, 
    {name:"British Gold Coins",bread:'/Gold/Gold-Coins/British-Gold-Coins'},{name:"Canadian Gold Coins",bread:'/Gold/Gold-Coins/Canadian-Gold-Coins',image:'/static/images/Canadian Gold Coins (1).png'},{name:"Chinese Gold Coins",bread:'/Gold/Gold-Coins/Chinese-Gold-Coins',image:'/static/images/Chinese Gold Coins (1).png'},{name:"Mexican Gold Coins",bread:'/Gold/Gold-Coins/Mexican-Gold-Coins',image:'/static/images/Mexican Gold Coins (1).png'},{name:"African Gold Coins",bread:'/Gold/Gold-Coins/African-Gold-Coins',image:'/static/images/African Gold Coins (1).png'},{name:"Other Gold Coins",bread:'/Gold/Gold-Coins/Other-Gold-Coins'}]
    
    let goldbarcats =[
    {name:"1 oz Gold Bars",bread:'/Gold/Gold-Bars/1-oz-Gold-Bars',image:'/static/images/1 oz Gold Bars (1).png'},
    {name:"5 oz Gold Bars",bread:'/Gold/Gold-Bars/5-oz-Gold-Bars'},
    {name:"10 oz Gold Bars",bread:'/Gold/Gold-Bars/10-oz-Gold-Bars'},
    {name:"Fractional Gold Bars",bread:'/Gold/Gold-Bars/Fractional-Gold-Bars',image:'/static/images/Fractional Gold Bars (1).png'}]
    let platcats = [
    {name:"Platinum Coins",bread:'/Platinum/Platinum-Coins',image:'/static/images/Plat Coins.png'},
    {name:"Platinum Bars",bread:'/Platinum/Platinum-Bars',image:'/static/images/Plat Bars.png'},]
    function escapeUrl(url) {
      // Escape '/' and space characters in the URL
      const escapedUrl = encodeURIComponent(url).replace(/%20/g, '+').replace(/%2F/g, '/').replaceAll("/","")
      return escapedUrl;
    }
  
    React.useEffect(()=>{
      if(itemList.length > 0 ){
        console.log("Creating list with items")
        console.log(itemList)
        const seoList = []

        for( let i = 0; i < itemList.length; i++){
          //const price = /calcPrice()
          let prodUrl = '/product/'+escapeUrl(itemList[i].name)

          let item ={
            "@type": "ListItem",
            "position": i+1,
            "url": "https://goldguysbullion.com"+prodUrl,
            "item": {
              "@type": "Product",
              "name": itemList[i].name,
              "image": itemList[i].mainImage,
              "description": itemList[i].descLong,
              "sku": itemList[i].sku,
            }
          }
          seoList.push(item)
        }
        console.log("HERE")
        console.log(seoList)

        const structuredData = {
          "@context": "https://schema.org",
          "@type": "ItemList",
          "itemListElement": 
            seoList
          }
        setSeoData(structuredData)

      }

    },[itemList])  

    if(isLoadingSearch){
      <div style={{textAlign:'center',margin:50,height:300}}>
      <CircularProgress/>
    </div>
    }else {
  
    let hideBreadCrumb = ""
    let hideMargin  = ""
    if(location.pathname == '/'){
      hideBreadCrumb = " hideBox"
      hideMargin  = " marginOverride"
    }



    return(
        <div className={'minH' + hideMargin}>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{pathParams[0]}</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content={pathParams[0]}/>
            <meta name="keywords" content="Silver, Gold, Bullion, Bars, Coins, Precious Metal"/>
            <script type="application/ld+json">
                {JSON.stringify(seoData)}
              </script>
        </Helmet>
            <div className={'fb breadcrumbcont '+ customHeaderBR + hideBreadCrumb}>
            <nav aria-label="breadcrumbs" className={customHeaderBR + " breadCrumbContainer"} vocab="https://schema.org/"
                        typeof="BreadcrumbList">
                {pathParams.map((item,index)=>{
                    let searchT = ""
                    if(searchVal!= ''){
                        searchT = " -("+ searchVal + ")"
                    }
                    let breadCrumb = ""
                    if(index == 0){
                      breadCrumb = "/"+pathParams[0]
                    }
                    if(index == 1){
                      breadCrumb = "/"+pathParams[0]+"/"+pathParams[1]
                    }
                    if(index == 2){
                      breadCrumb = "/"+pathParams[0]+"/"+pathParams[1]+"/"+pathParams[2]
                    }

                    let itemTitle = item
                    if("Deals" == item){
                      itemTitle = "DEAL ZONE"
                    }
                    const url = breadCrumb.replaceAll(" ", "-")

                    return(
                      <>
                            {pathParams.length-1 > index ? 
                            <Link to={url} state={{ breadCrumb: breadCrumb }} property={itemTitle} typeof="WebPage" className='breadCrumbSpacing'>
                            {capitalizeFirstLetter(itemTitle)} {' >'}
                            <meta property="position" content={index}></meta>
                            </Link>
                             : capitalizeFirstLetter(itemTitle) + searchT
                        }
                                            </>

                    )
                })}
                                        </nav>

            </div>
            <div className='catImageTopContainer'>
            
            {location.pathname == "/Silver" && silverSubCats.map((coin, index) => {
                let urll = "/" + coin.name.replaceAll(" ", "-")
                return (
                  <div key={index} className='catImageContainer'>
                  <Link to={coin.bread} state={{ breadCrumb: coin.bread }} style={{display:'flex',justifyContent:'center'}}>
                    <CardMedia
                      sx={{ height: 100,width:'100%'}}
                      image={coin.image != undefined ? coin.image :"/static/images/4.png"}
                      className='catImageImage'
                      loading='eager'
                    />
                    </Link>
                    <CardContent style={{background:'transparent'}}>
                      <Typography gutterBottom style={{fontSize:13}}component="div">
                        {coin.name}
                      </Typography>
                    </CardContent>
                    </div>
                )})}
                {location.pathname == "/Gold" && goldSubCats.map((coin, index) => {
                let urll = "/" + coin.name.replaceAll(" ", "-")
                return (
                    <div key={index} style={{marginRight:30}}>
                    <Link to={coin.bread } state={{ breadCrumb: coin.bread }} style={{display:'flex',justifyContent:'center'}}>
                    <CardMedia
                      sx={{ height:  115,width:115}}
                      image={coin.image != undefined ? coin.image :"/static/images/4.png"}
                      className='catImageImage'
                      loading='eager'
                    />
                    </Link>
                    <CardContent style={{background:'transparent'}} >
                      <Typography gutterBottom style={{fontSize:13,textAlign:'center'}}component="div">
                        {coin.name}
                      </Typography>
                    </CardContent>
                    </div>
                )})}
            {location.pathname == "/Silver/Silver-Coins" && coincats.map((coin, index) => {
                let urll = "/" + coin.name.replaceAll(" ", "-")
                return (
                  <div key={index} className='catImageContainer'>
                  <Link to={coin.bread} state={{ breadCrumb: coin.bread }} style={{display:'flex',justifyContent:'center'}}>
                    <CardMedia
                      sx={{ height:  115,width:115}}
                      image={coin.image != undefined ? coin.image :"/static/images/4.png"}
                      className='catImageImage'
                      loading='eager'
                    />
                    </Link>
                    <CardContent style={{background:'transparent'}} >
                      <Typography gutterBottom style={{fontSize:13}}component="div">
                        {coin.name}
                      </Typography>
                    </CardContent>
                    </div>
                )})}
                {location.pathname == "/Silver/Silver-Bars" && silverBarSubcats.map((coin, index) => {
                let urll = "/" + coin.name.replaceAll(" ", "-")
                return (
                  <div key={index} className='catImageContainer'>
                  <Link to={coin.bread} state={{ breadCrumb: coin.bread }} style={{display:'flex',justifyContent:'center'}}>
                    <CardMedia
                      sx={{ height:  115,width:115}}
                      image={coin.image != undefined ? coin.image :"/static/images/4.png"}
                      className='catImageImage'
                      loading='eager'
                    />
                    </Link>
                    <CardContent style={{background:'transparent'}} >
                      <Typography gutterBottom style={{fontSize:13}}component="div">
                        {coin.name}
                      </Typography>
                    </CardContent>
                    </div>
                )})}
                {location.pathname == "/Gold/Gold-Coins" && goldcoincats.map((coin, index) => {
                let urll = "/" + coin.name.replaceAll(" ", "-")
                return (
                  <div key={index} className='catImageContainer'>
                  <Link to={coin.bread} state={{ breadCrumb: coin.bread }} style={{display:'flex',justifyContent:'center'}}>
                    <CardMedia
                      sx={{ height:  115,width:115}}
                      image={coin.image != undefined ? coin.image :"/static/images/4.png"}
                      className='catImageImage'
                      loading='eager'
                    />
                    </Link>
                    <CardContent style={{background:'transparent'}}>
                      <Typography gutterBottom style={{fontSize:13}}component="div">
                        {coin.name}
                      </Typography>
                    </CardContent>
                    </div>
                )})}
                {location.pathname == "/Gold/Gold-Bars" && goldbarcats.map((coin, index) => {
                let urll = "/" + coin.name.replaceAll(" ", "-")
                return (
                  <div key={index} className='catImageContainer'>
                  <Link to={coin.bread} state={{ breadCrumb: coin.bread }} style={{display:'flex',justifyContent:'center'}}>
                    <CardMedia
                      sx={{ height:  115,width:115}}
                      image={coin.image != undefined ? coin.image :"/static/images/4.png"}
                      className='catImageImage'
                      loading='eager'
                    />
                    </Link>
                    <CardContent style={{background:'transparent'}} >
                      <Typography gutterBottom style={{fontSize:13}}component="div">
                        {coin.name}
                      </Typography>
                    </CardContent>
                    </div>
                )})}
                {location.pathname == "/Platinum" && platcats.map((coin, index) => {
                let urll = "/" + coin.name.replaceAll(" ", "-")
                return (
                  <div key={index} className='catImageContainer'>
                  <Link to={coin.bread} state={{ breadCrumb: coin.bread }} style={{display:'flex',justifyContent:'center'}}>
                    <CardMedia
                      sx={{ height:  115,width:115}}
                      image={coin.image != undefined ? coin.image :"/static/images/4.png"}
                      className='catImageImage'
                      loading='eager'
                    />
                    </Link>
                    <CardContent style={{background:'transparent'}} >
                      <Typography gutterBottom style={{fontSize:13}}component="div">
                        {coin.name}
                      </Typography>
                    </CardContent>
                    </div>
                )})}
            </div>
            {showSort && 
            <div className={'fb jsb '+ headerBackgroundStyle} style={{padding:'5px 0px 0px 0px',marginBottom:20}} >
            <div className='searchResCont' >
              ({resultNumber}) results
            </div>
            <FormControl style={{width:300,marginBottom:5,marginRight:5}}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={indexV}
                        onChange={handleChange}
                    >
                        <MenuItem value={"most_popular"}>Most Popular</MenuItem>
                        <MenuItem value={"item_price_asc"}>Price: Low to High</MenuItem>
                        <MenuItem value={"item_price_desc"}>Price: High to Low</MenuItem>
                        <MenuItem value={"date_desc"}>Date Added</MenuItem>

                    </Select>
                    </FormControl>
            </div>
            }
            { window.location.pathname == "/" &&
            <div style={{marginLeft:20,paddingBottom:30,fontSize:26}}>
            {imageCat}
            </div>
            }

            <div className='fb fw jsb' id="areas"
            style={{backgroundImage: `url(/static/images/GoldGuysVar2.png)`}}>
            {itemList != undefined && itemList.map((item,index)=>{
                return(
                    <PLPCard key={index} item={item}/>
            )})}
            {itemList != undefined && 
                itemList.length == 0
                    &&
                    <div style={{margin:'150px 0px',textAlign:'center',width:'100%',background:'transparent',fontWeight:'bolder'}}>
                    No Results
                    </div>
            }
            </div>
        </div>
    )

          }
}